<template>
    <div class="instrument-verzoek">
        <v-container fluid>
            <v-row>
                <v-col cols=12 md=8>
                    <h1 class="font-weight-black">Contact</h1>
                    <h2 class="font-weight-black mb-2">Hier neem je contact met ons op</h2>

                    <template v-if="contact">
                        <p>
                            Klopt er iets niet in deze gids of mis je een instrument of aanbieder? laat het ons weten.
                            Samen zorgen we ervoor dat de gids compleet en actueel is.
                        </p>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-alert
                                    :value="alert"
                                    type="success"
                                    transition="scale-transition"
                                >Uw verzoek is ingediend. Bedankt!
                                </v-alert>
                                <v-text-field
                                    v-model="naam"
                                    label="Uw naam"
                                    outlined
                                ></v-text-field>
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    placeholder="Beschrijving instrument/aanbieder"
                                    v-model="verzoek"
                                ></v-textarea>
                                <v-btn @click="verstuurVerzoek" :disabled="!verzoek.length" class="send">Verstuur
                                    verzoek
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-else>
                        <p>
                            Er is geen contactpersoon beschikbaar voor deze omgeving
                        </p>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss" scoped>
    .instrument-verzoek {
        p {
            font-weight: 500;
            line-height: 1.8;
        }

        .side p {
            line-height: 1.5;
        }
        .theme--light.v-btn {
            &.send {
                background-color: var(--primary);
                color: #ffffff;
            }
        }
    }
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import emailjs from '@emailjs/browser'

// EmailJs params
const emailjServiceId = process.env.VUE_APP_EMAIL_SERVICE_ID
const emailjTemplateId = process.env.VUE_APP_EMAIL_TEMPLATE_ID
const emailjUserId = process.env.VUE_APP_EMAIL_USER_ID

export default {
  data () {
    return {
      naam: '',
      verzoek: '',
      alert: false
    }
  },
  computed: {
    ...mapGetters('environment', [
      'environment'
    ]),
    ...mapGetters('auth', [
      'user'
    ]),
    contact () {
      if (!this.environment) {
        return null
      }
      return this.environment.contact
    }
  },
  mounted () {
    // fetch the environment data to get current contact data
    this.initEnvironment(true)
  },
  methods: {
    ...mapActions('environment', [
      'initEnvironment'
    ]),
    verstuurVerzoek () {
      const instrumentenVerzoek = this
      const environmentEmailAddress = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_TEST_CONTACT_EMAIL : this.contact.email
      const environmentContact = process.env.VUE_APP_ENV === 'local' ? process.env.VUE_APP_TEST_CONTACT_NAME : this.contact.name
      let message = `${this.naam} schreef: ${this.verzoek}`

      if (this.user) {
        message += '\n\nIngelogde professional: ' + this.user.attributes.email
      }

      const msg = {
        environmentEmailAddress,
        environmentContact,
        fromName: this.naam,
        message
      }
      emailjs.send(emailjServiceId, emailjTemplateId, msg, emailjUserId)
        .then(function (response) {
          instrumentenVerzoek.clearForm()
          instrumentenVerzoek.flashAlert()
        }, function (error) {
          instrumentenVerzoek.flashError(error)
        })
    },
    clearForm () {
      this.naam = ''
      this.verzoek = ''
    },
    flashAlert () {
      this.alert = true
      setTimeout(() => {
        this.alert = false
      }, 3000)
    },
    flashError (error) { // TODO: display error
      alert(error)
    }
  }
}
</script>
