import axios from 'axios'
import ElasticSearch from './ElasticSearch'

const resource = 'tiles'

export default class TilesES extends ElasticSearch {
  static get indexes () {
    return this.getEnvironmentIndexForResource(resource)
  }

  static async getTiles () {
    await this.ensureIndex(resource)
    const searchParams = {
      size: 100
    }
    return axios.post(`/${this.endpoint}/${this.indexes}/_search`, searchParams)
  }
}
