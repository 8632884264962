<template>
    <v-expansion-panel-content>
        <slot />
    </v-expansion-panel-content>
</template>

<script>
export default {
  name: 'ExpansionPanelContent'
}
</script>

<style lang="scss" scoped>
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 8px 24px 16px;
    }
</style>
