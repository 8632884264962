import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

export default {
  namespaced: true,
  state: () => {
    return {
      authState: undefined,
      authFlowUser: undefined,
      loading: false,
      user: undefined,
      states: [
        'signed-out',
        'multi-factor-token',
        'signed-in',

        'request-password-reset',
        'password-reset-requested',

        'new-password-required',
        'new-password-submitted',

        'signup',
        'signout',
        'signin',
        'loading',
        'signingup',
        'confirmSignUp',
        'confirmsignupcustomflow',
        'confirmSignIn',
        'confirmingsignincustomflow',
        'verifyingattributes',
        'forgotpassword',
        'resettingpassword',
        'settingMFA',
        'TOTPSetup',
        'customConfirmSignIn',
        'verifyContact'
      ]
    }
  },
  mutations,
  actions,
  getters
}
