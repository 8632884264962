<template>
    <v-container fluid>
        <v-row>
            <v-col cols=2>
                <figure style="height: 50px;">
                    <img src="@/assets/images/handleiding/logos/logoVNG.png"
                         alt="vng logo"
                         style="object-fit: contain; width: 100%; height: 100%;"
                    />
                </figure>
            </v-col>
            <v-col cols=7></v-col>
            <v-col cols=3>
                <figure style="height: 50px;">
                    <img src="@/assets/images/handleiding/logos/logoTNO.png"
                         alt="tno logo"
                         style="object-fit: contain; width: 100%; height: 100%;"
                    />
                </figure>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=8>
                <h1 class="font-weight-black">
                    Handreiking methodisch werken met <b>Eva</b>
                </h1>
                <p>
                    Op deze pagina vind je de volledige handreiking over methodisch werken met Eva. Dit wordt aan de
                    hand van vier stappen uitgelegd. Deze handreiking is ook
                    <a href="https://vng.nl/sites/default/files/2022-11/Eva_Handreiking_Methodisch_werken_nov_2022.pdf"
                       class="links"
                       target="_blank">
                        beschikbaar als pdf
                    </a>.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=8>
                <h3>
                    Vragen over instrumentengids Eva of deze handreiking?
                </h3>
                <p>
                    Neem contact op met onze servicedesk via
                    <a href="mailto:servicedesk@inlichtingenbureau.nl" class="links" target="_blank">servicedesk@inlichtingenbureau.nl</a>
                </p>
                <p>
                    Of kijk voor achtergrondinformatie op:
                    <a href="https://www.inlichtingenbureau.nl/Beleidsterreinen/Werk-en-Bestaanszekerheid/Re-integratie/Dennis-Eva" class="links" target="_blank">
                        www.inlichtingenbureau.nl/Beleidsterreinen/Werk-en-Bestaanszekerheid/Re-integratie/Dennis-Eva
                    </a>
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=8>
                <div id="disclaimer">
                    <header>
                        Disclaimer
                    </header>
                    <p>
                        Eva wordt continu doorontwikkeld. Het kan daarom zijn dat deze handleiding inmiddels
                        achterhaald
                        is. De meest recente versies van alle ondersteuningsmaterialen zijn te vinden op
                        <a href="https://vng.nl/projecten/instrumentengidsen-dennis-en-eva" class="links"
                           target="_blank">
                            de website van VNG Realisatie.
                        </a>
                    </p>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=8>
                <v-expansion-panels
                    class="handleiding-accordion klantkenmerk-accordion"
                >
                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Wat is Eva?
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
<!--                            <h2 class="paragrafen">-->
<!--                                Wat is Eva?-->
<!--                            </h2>-->
                            <p>
                                Om werkzoekenden optimaal te laten ontwikkelen is individueel maatwerk nodig. De
                                haalbare eerstvolgende stap is voor iedere persoon anders en vraagt daarmee een andere aanpak.
                                Daarom hebben gemeenten een rijk aanbod aan instrumentarium om in te zetten, passend bij de
                                verschillende personen in het werkzoekendenbestand. Maar hoe houd je overzicht, hoe weet je wat je
                                in kunt zetten en onder welke voorwaarden? Welke instrumenten zijn effectief? Om gemeenten en
                                samenwerkingsverbanden hierin te ondersteunen, faciliteert VNG gemeenten met de digitale
                                instrumentengids Eva. Eva staat voor effectief, vakbekwaam en actueel. Eva is ontwikkeld door VNG,
                                in samenwerking met gemeenten, TNO, Sam en Divosa. We werken hierin samen om effectief gebruik van
                                instrumenten te ondersteunen en deze kennis te verspreiden.
                            </p>
                            <p class="definitie">
                                Eva is een digitale ondersteuningstool, die gemeentelijke organisaties zelf kunnen
                                vullen met lokale instrumenten die bijdragen aan maximaal meedoen en/of de begeleiding
                                naar werk. Door
                                middel van filters en zoekfuncties kunnen professionals in één oogopslag zien welke
                                instrumenten
                                beschikbaar zijn voor een specifieke werkzoekende. Naast maatwerk faciliteert Eva ook
                                beleidsmatige
                                verbeteringen door het aanbod inzichtelijk en transparant te maken. Er wordt zichtbaar
                                of het
                                instrumentenaanbod optimaal is en hoe deze instrumenten worden beoordeeld door de
                                professionals.
                            </p>
                        </ExpansionPanelContent>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Voor wie is deze handreiking?
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <!--                            <h3 class="paragrafen">-->
                            <!--                                Voor wie is deze handreiking?-->
                            <!--                            </h3>-->
                            <p>
                                Deze handreiking is ontwikkeld omdat veel gemeenten behoefte hebben aan eenzelfde manier
                                om hun
                                instrumenten weer te geven in Eva. Om ervoor te zorgen dat iedere gemeente eenzelfde
                                ‘taal’ hanteert bij
                                de beschrijving van de instrumenten is een instrumentenformat ontwikkeld dat is ver-
                                werkt in Eva.
                                Hierdoor worden instrumenten volgens een standaard opbouw ingevoerd in Eva. De
                                beschrijving van de
                                instrumenten via het instrumentenformat sluit aan bij inzichten vanuit de weten-
                                schappelijke
                                literatuur. Door de gestandaardiseerde opbouw kunnen professionals vervolgens een-
                                voudig zoeken naar
                                aanbod passend bij de betreffende klant. Deze handreiking is primair geschre- ven voor
                                re-integratie
                                professionals (klantmanagers). Aan de hand van vier stappen wordt methodisch werken met
                                Eva uitgelegd.
                                Vervolgens wordt de geadviseerde manier van zoeken in Eva uitgelegd.
                                Ondersteuningsmateriaal voor andere
                                onderdelen is te vinden op
                                <a href="https://vng.nl/projecten/instrumentengidsen-dennis-en-eva" class="links"
                                   target="_blank">
                                    de website van VNG Realisatie.
                                </a>
                            </p>
                            <p class="info">
                                <b>Let op:</b>
                                Deze handreiking gaat niet in op de verschillende functionaliteiten van de voorkant, dus
                                de front end, van Eva. Er is een aparte handleiding geschreven over hoe te werken met
                                Eva, zoals inlog- gen en bijvoorbeeld zoeken en filteren getiteld: “Handleiding Eva voor
                                klantmanagers”. Deze is te vinden op
                                <a href="https://vng.nl/projecten/instrumentengidsen-dennis-en-eva"
                                   class="links"
                                   target="_blank">
                                    de website van VNG Realisatie.
                                </a>
                            </p>
                            <p>
                                Deze handreiking is tot stand gekomen tijdens een pilot, naar aanleiding van vier
                                focusgroepbijeen-
                                komsten met de subgroep ‘Eenheid van Taal’, onder leiding van TNO en VNG. In deze
                                subgroep
                                participeerden zes gemeentelijk organisaties, namelijk: Amsterdam, BBS UO, Dalfsen, Den
                                Haag, RSD de
                                Liemers en Werkzaak Rivierenland. De resultaten van de focusgroepen zijn verwerkt in het
                                Eva
                                instrumentenformat en daarnaast geaccordeerd door de Werkgroep Eva van VNG.
                            </p>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Begrippenkader
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <p>
                                In deze handreiking wordt gebruik gemaakt van de term klant (cliënt, burger,
                                werkzoekende, …):
                            </p>
                            <p class="definitie">
                                Een klant is een inwoner die in het kader van de Participatiewet begeleid wordt bij
                                het vinden van algemeen geaccepteerde arbeid, een re-integratie traject,
                                vrijwilligerswerk of
                                scholing.
                            </p>
                            <p>
                                Daarnaast wordt er in deze handreiking geschreven over de professional (klantmanager,
                                regisseur, werkcoach, werkconsulent, …):
                            </p>
                            <p class="definitie">
                                Een professional is de persoon die de klant vanuit de gemeente ondersteunt.
                            </p>
                            <p>
                                Eerst beschrijven we in deze handreiking wat een instrument inhoudt en wat er wordt
                                verstaan onder kenmerken in het instrumentenformat. Hierbij wordt een onderscheid
                                gemaakt tussen meer inhoudelijke kenmerken – basiskenmerken – en praktische kenmerken.
                                Vervolgens beschrijven we hoe een professional methodisch kan werken met Eva door via de
                                kenmerken te zoeken naar instrumenten in Eva. In bijlage 1 wordt een beschrijving
                                gegeven van de theoretische modellen die de basis vormden voor de kenmerken gericht op
                                de klant van het instrumentenformat.
                            </p>
                            <section>
                                <h3 class="paragrafen">
                                    Wat is een instrument?
                                </h3>
                                <p class="definitie">
                                    Een instrument is een middel dat op tijdelijke basis ingezet wordt om een
                                    vastgesteld doel te bereiken binnen het ontwikkeltraject richting werk,
                                    participatie, ondernemerschap of scholing van de klant.
                                </p>
                                <p>
                                    Het gaat bij een instrument om wat je extra inzet om de klant zich verder te laten
                                    ontwikkelen richting werk of participatie. De gespreksvaardigheden/het handelen van
                                    de professional zijn geen instrumenten op zich. Gemeentelijke organisaties kunnen
                                    Eva zelf vullen met (lokale) instrumenten.
                                </p>
                            </section>
                            <section>
                                <figure>
                                    <img src="@/assets/images/handleiding/figuur1_tegels.png"
                                         alt="werklandschap tegels"
                                         style="object-fit: contain; width: 100%; height: 100%;"
                                    />
                                    <figcaption class="figuren">
                                        <strong>Figuur 1.</strong> Tegels van het werklandschap Eva
                                    </figcaption>
                                </figure>
                                <h3 class="paragrafen">Beschrijving kenmerken</h3>
                                <p>
                                    De instrumenten in Eva worden beschreven op verschillende kenmerken. We
                                    onderscheiden daarin basiskenmerken en praktische kenmerken.
                                </p>
                                <ul>
                                    <li>
                                        <strong>De basiskenmerken</strong> zijn de kenmerken die inhoudelijk helpen om
                                        te zoeken naar instrumenten die passen bij de klant. We onderscheiden daarbij
                                        een tweetal kenmerken: tegels en klantkenmerken.
                                        <ul>
                                            <li>
                                                Het eerste basiskenmerk bestaat uit <strong>de tegels van het
                                                werklandschap Eva </strong> (zie Figuur 1). Dit werklandschap
                                                visualiseert de generieke dienstverlening die gemeenten bieden om
                                                klanten te begeleiden naar participatie of (on)betaald werk. Het bestaat
                                                uit verschillende dienstverleningstegels. Bij iedere ‘tegel’ staat een
                                                beschrijving wat gemeenten doen in dit element van de dienstverlening en
                                                hoe dit het best vormgegeven kan worden. Het landschap is geen
                                                stroomschema. Het zijn losse elementen die per klant samengesteld worden
                                                in een aantal tegels en de volgorde ervan. Waar nodig kunnen de tegels
                                                herhaald worden. In bijlage 2 worden de tegels beschreven.
                                            </li>
                                            <li>Het tweede basiskenmerk <strong>klantkenmerken</strong> gaat over
                                                veranderbare kenmerken van de klant die invloed hebben op het meedoen in
                                                de maatschappij, vinden en behoud van werk. In bijlage 3 worden de
                                                klantkenmerken beschreven. In bijlage 4 wordt weergegeven hoe deze
                                                klantkenmerken samenhangen met de theoretisch achtergrond: de onderdelen
                                                uit het integratieve gedragsmodel (IGM).
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        De <strong>praktische kenmerken</strong> geven een beschrijving van de opbouw
                                        van een instrument. Denk hierbij aan de specifieke informatie over het
                                        instrument, zoals het doel, de aanbieder, de locatie, de duur, de kosten en hoe
                                        je iemand kunt aanmelden. In bijlage 5 worden de verschillende praktische
                                        kenmerken beschreven.
                                    </li>
                                </ul>
                                <p>
                                    Zie Figuur 2 voor een overzicht van alle basiskenmerken en praktische kenmerken van
                                    het instrumentenformat.
                                </p>
                                <figure>
                                    <img src="@/assets/images/handleiding/figuur2_instrumentenFormat_communicatie.png"
                                         alt="instrumenten format"
                                         style="object-fit: contain; width: 100%; height: 100%;"
                                    />
                                    <figcaption class="figuren">
                                        <strong>Figuur 2.</strong> Het instrumentenformat met een overzicht van alle in
                                        te voeren kenmerken
                                    </figcaption>
                                </figure>
                            </section>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Methodisch werken met Eva
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <p>
                                Het gebruik van Eva is onderdeel van methodisch werken door de professional. Methodisch
                                werken bestaat uit een systematische, doorzichtige en doelgerichte manier van werken,
                                met regelmatige evaluaties om klanten te begeleiden. Het is gebaseerd op de
                                Plan-Do-Check-Act (PDCA) - cyclus. Op basis van de PDCA-cyclus bestaat methodisch
                                werken uit vier stappen die de professional bij de begeleiding van een klant telkens
                                doorloopt. Figuur 3 geef deze vier stappen weer.
                            </p>
                            <figure>
                                <img src="@/assets/images/handleiding/figuur3_methodischWerken.png"
                                     alt="methodisch werken"
                                     style="object-fit: contain; width: 100%; height: 100%;"
                                />
                                <figcaption class="figuren">
                                    <strong>Figuur 3.</strong> De vier stappen van methodisch werken. Gebruik Eva op het
                                    moment dat het klantbeeld en het doel duidelijk is. Selecteer In Eva instrumenten
                                    aan de hand van deze basiskenmerken. Verfijn de keuze aan de hand van praktische
                                    kenmerken.
                                </figcaption>
                            </figure>
                            <p>
                                In stap 1 ‘voorbereiding en diagnose’ moet er eerst een klantbeeld worden gevormd door
                                informatie te verzamelen (via de melding, het gesprek tussen klant en professional en
                                mogelijke diagnostiek), een eerste screening van deze gegevens en tot slot de
                                interpretatie van deze gegevens. Vervolgens is het in stap 2 ‘opstellen trajectplan’
                                aan de professional en de klant om te bespreken wat dit klantbeeld inhoudt m.b.t. werk
                                zoeken en participatie in de maatschappij. Zijn er bijvoorbeeld bepaalde
                                aandachtspunten zodanig belemmerend dat eerst hieraan gewerkt moet worden of kan er
                                direct passend werk of mogelijkheden tot participatie gevonden worden? In stap 3 wordt
                                het trajectplan uitgevoerd, gemonitord en eventueel bijgestuurd. Tot slot wordt in
                                stap 4 door de professional teruggeblikt op de gevolgde werkwijze eventueel met
                                collega’s en/of klanten om na te gaan wat wel of juist niet werkte in het traject en de
                                ingezette instrumenten.
                            </p>
                            <p>
                                Eva helpt bij verschillende stappen van methodisch werken. De basiskenmerken
                                <strong>tegels van het werklandschap </strong>Eva en <strong>klantkenmerken </strong>
                                worden gebruikt om instrumenten te selecteren bij de verschillende stappen. Bij stap 1
                                ‘voorbereiding en diagnose’ kan via Eva gezocht worden naar diagnose instrumenten om het
                                klantbeeld aan te vullen. Hiervoor kan men de tegel ‘diagnostiek’ selecteren uit het
                                basiskenmerk ‘tegels van het werklandschap Eva’. Men kan naar specifieke diagnose
                                instrumenten zoeken door ook één of meer klantkenmerken te selecteren, zoals een
                                aandachtspunt of vaardigheden. Eva ondersteunt vooral bij stap 2 door instrumenten te
                                vinden die passen bij de kenmerken van het klantbeeld verkregen in stap 1, waar de
                                professional en klant in onderling overleg aan willen werken.
                            </p>
                            <p>
                                Via Eva kan in stap 2 gezocht worden naar passende instrumenten die aansluiten op de
                                situatie van de klant op dat moment. Eva is ook nadrukkelijk bedoeld om de klant te
                                betrekken bij het vaststellen van doelen en de keuze voor een instrument. Zowel het
                                klantbeeld (stap 1) als de instrumenten (stap 2) worden beiden beschreven door de
                                basiskenmerken ’tegels van het werklandschap Eva’ en ‘klantkenmerken’. Op deze manier
                                leggen de basiskenmerken de verbinding tussen het klantbeeld (stap 1) en de in te zetten
                                instrumenten (stap 2). De tegels bepalen welk persoonlijk pad de klant bewandelt
                                richting werk of participatie, waar de klant zich nu bevindt en waar de klant naar toe
                                wil. De klantkenmerken geven aan waaraan gewerkt kan worden om richting de volgende
                                tegel te gaan.
                            </p>
                            <p>
                                In stap 3 ‘uitvoeren trajectplan’ wordt gekeken of het ingezette instrument aan het
                                gestelde doel beantwoordt. Indien dit niet of onvoldoende het geval is, kan opnieuw een
                                ander instrument gezocht worden om het doel te bereiken. Of als het doel behaald is kan
                                een ander doel gesteld worden en een bijbehorend instrument gezocht worden. In het
                                uitvoeren van het trajectplan wordt het klantbeeld steeds aangevuld en worden
                                bijbehorende instrumenten gezocht door de tegels en klantkenmerken te combineren.
                            </p>
                            <p>
                                In stap 4 ‘terugblik op werkwijze’ vindt een evaluatie op de gestelde doelen plaats. De
                                professional evalueert eventueel met collega’s en/of klanten het gevolgde traject en de
                                ingezette instrumenten om hiervan te leren. Dit proces kan ondersteund worden door een
                                beoordelingsfunctionaliteit (review functie) in Eva. Instrumenten kunnen individueel
                                beoordeeld worden in Eva aan de hand van een vijfsterrensysteem. Deze
                                beoordelingsfunctionaliteit helpt om na te denken over het effect van het ingezette
                                instrument. Op termijn zal er daardoor onderscheid ontstaan tussen goed beoordeelde en
                                minder goed beoordeelde instrumenten.
                            </p>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Methodisch zoeken in Eva
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <p>
                                Hieronder beschrijven we hoe Eva gebruikt kan worden om instrumenten te zoeken. Het
                                dringende advies is om hierin twee stappen te volgen. Selecteer in stap 1 eerst
                                instrumenten aan de hand van de <strong>basiskenmerken </strong>en verfijn de selectie
                                in stap 2 aan de hand van de <strong>praktische kenmerken</strong>. In Figuur 3 zijn
                                deze stappen terug te vinden in de <span class="pinkReference">roze</span> blokken. In
                                Figuur 3 is ook te zien hoe deze kenmerken zich verhouden tot de stappen van het
                                methodisch werken met Eva als geheel zoals hierboven beschreven. Het advies om in twee
                                stappen te zoeken is tot stand gekomen in overleg met professionals van verschillende
                                gemeenten die deelnamen aan de focusgroepbijeenkomsten in de pilotfase.
                            </p>
                            <p class="info">
                                <strong>Let op:</strong> Deze handreiking gaat niet in op het gebruik van de voorkant
                                van Eva zelf. Bijvoorbeeld waar op te klikken en hoe een filter te selecteren om
                                instrumenten te vinden. Hierover is een aparte handleiding geschreven getiteld:
                                “Handleiding Eva voor de klantmanager”. Deze is te vinden op
                                <a href="https://vng.nl/projecten/instrumentengidsen-dennis-en-eva" target="_blank"
                                   class="links">
                                    de website van VNG Realisatie.
                                </a>
                            </p>
                            <h3 class="paragrafen">Stap 1: gebruik de basiskenmerken</h3>
                            <p>
                                In Eva kunnen één of meer tegels worden geselecteerd van het
                                <strong>werklandschap</strong>om te bepalen waar de klant staat en naartoe wil. Bij het
                                selecteren van één of meer tegels worden de instrumenten getoond die onder deze tegel(s)
                                vallen. Het tweede basiskenmerk <strong>klantkenmerken</strong> gaat over kenmerken van
                                de klant die invloed hebben op het meedoen in de maatschappij, vinden en behoud van
                                werk. Als je een klantkenmerk selecteert zie je instrumenten die betrekking hebben op
                                dat betreffende kenmerk. Ook kunnen tegels en klantkenmerken gecombineerd worden door
                                tegels en kenmerken tegelijkertijd te selecteren. Door tegels te combineren met
                                klantkenmerken kan er preciezer gezocht worden naar instrumenten. Door met deze twee
                                basiskenmerken te zoeken naar instrumenten worden instrumenten gevonden worden die
                                ondersteunen bij het zetten van de volgende stap die de klant en de professional hebben
                                afgesproken. Zie bijlage 2 en bijlage 3 voor een overzicht van de basiskenmerken.
                            </p>
                            <h3 class="paragrafen">Stap 2: gebruik de praktische kenmerken</h3>
                            <p>
                                Nadat men via de basiskenmerken instrumenten heeft geselecteerd kan er nog verder
                                gespecificeerd worden op praktische kenmerken. Dit is informatie over het instrument,
                                zoals een beschrijving van het instrument, wie de aanbieder is, de locatie, de duur, de
                                kosten en hoe je iemand kunt aanmelden. Als een instrument geschikt is voor een
                                specifieke doelgroep kan hierop ook verder gespecificeerd worden. Zoals hierboven
                                beschreven is het advies om praktische kenmerken als laatste stap te specificeren. Deze
                                geadviseerde volgorde (stap 1: basiskenmerken en stap 2: praktische kenmerken) leidt
                                ertoe dat er instrumenten ingezet worden die passen bij de doelen die de klant en de
                                professional willen realiseren. Zie bijlage 4 voor een overzicht van de praktische
                                kenmerken.
                            </p>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Bijlage 1. Theoretische modellen
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <section>
                                <h2 class="bijlageTitel">Theoretische modellen</h2>
                                <h3 class="paragrafen" id="igm">Het integratieve gedragsmodel</h3>
                                <p>
                                    Het ‘Integrative Model of Behavioral Prediction’ (Fishbein, 2008; Fishbein &amp;
                                    Ajzen, 2010 <a href="#voetnoot1" class="voetnoten">*</a>) is een model dat
                                    uitgebreid is getest en toegepast op verschillende onderwerpen en gedragingen, ook
                                    op het werk zoeken en vinden (bijv. Blonk 2018; Van Hooft, 2016). De versie van het
                                    integratieve gedragsmodel (IGM) van Blonk (2018) weergegeven in figuur 4 wordt
                                    gebruikt. Het IGM hanteert de drie kernbegrippen die in de re-integratie van mensen
                                    met een kwetsbare arbeidsmarktpositie centraal staan: motivatie, gedrag en
                                    perspectief ofwel willen, kunnen en het te bereiken en vast te houden doel. Het
                                    model beschrijft hoe deze begrippen samenhangen en de concepten die daarop van
                                    invloed zijn. Het willen oftewel de zoekintentie wordt beïnvloed door drie
                                    concepten: attitude, sociale norm en eigen-effectiviteit:
                                </p>
                                <ul>
                                    <li>
                                        <strong>attitude</strong> (houding) ten aanzien van gedrag wordt bepaald door de
                                        verwachte positieve en negatieve gevolgen van dat gedrag;
                                    </li>
                                    <li>
                                        <strong>sociale norm</strong> verwijst naar de sociale druk die een persoon
                                        ervaart. Deze bestaat uit:
                                        <ol style="list-style-type: lower-alpha;">
                                            <li>de descriptieve norm, namelijk het aantal andere personen dat hetzelfde
                                                gedrag vertoont, en
                                            </li>
                                            <li>de subjectieve norm, die bestaat uit wat een persoon denkt wat
                                                belangrijke andere personen
                                                vinden wat hij/zij moet doen in combinatie met het belang dat de persoon
                                                hecht aan de mening van
                                                die personen;
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <strong>eigen-effectiviteit</strong> (‘self-efficacy’) oftewel het ‘geloof in
                                        eigen kunnen’ is de verwachting van een persoon over de eigen mogelijkheden om
                                        het gedrag uit te voeren, ook onder moeilijke omstandigheden.
                                    </li>
                                </ul>
                                <p>
                                    Om van willen naar doen te gaan zijn drie concepten van belang: vaardigheden,
                                    zelfregulering en belemmeringen. Interessant aan het IGM is dat het aangeeft dat
                                    aangrijpen op veranderbare concepten een kernaspect is van het begeleiden naar werk
                                    of participatie van klanten (wat) en dat je dat op verschillende manieren kan doen
                                    (hoe). Het IGM is een model waarmee de situatie en omstandigheden van de klant
                                    inzichtelijk kunnen worden gemaakt, het helpt de professional de informatie over de
                                    klant te wegen en op basis daarvan de interventies te structureren (Boermans, Van de
                                    Logt &amp; Blonk, 2020).
                                </p>
                                <figure>
                                    <img src="@/assets/images/handleiding/figuur4_igm.png"
                                         alt="het integratieve gedragsmodel"
                                         style="object-fit: contain; width: 100%; height: 100%;"
                                    />
                                    <figcaption class="figuren">
                                        <strong>Figuur 4</strong> IGM toegepast op werkzoekgedrag (naar Blonk, 2018).
                                    </figcaption>
                                </figure>
                                <p class="voetnoten">
                                    <a id="voetnoot1">*</a>) Dit model is de opvolger van het model van gepland gedrag
                                    (Ajzen, 1991)
                                </p>
                            </section>

                            <section>
                                <h3 class="paragrafen">
                                    Model van kwetsbaarheid van Wanberg
                                </h3>
                                <p>
                                    Het concept belemmeringen uit het IGM is aangevuld met het model van kwetsbaarheid
                                    van Wanberg, Hough, en
                                    Song (2002). Figuur 5 geeft weer dat vijf van de zeven concepten van dat model
                                    betrekking hebben op
                                    kenmerken van het individu (Menselijk Kapitaal, Sociaal Kapitaal, Economische
                                    beloning, Belemmeringen
                                    (praktisch, psychisch, fysiek, verstandelijk) en twee concepten hebben betrekking op
                                    werkgevers
                                    (Discriminatie, Vraagversterking arbeidsmarkt).
                                </p>
                                <figure>
                                    <img src="@/assets/images/handleiding/bijlage1_modelWanberg.png"
                                         alt="model Wanberg"
                                         style="object-fit: contain; width: 100%; height: 100%;"
                                    />
                                    <figcaption class="figuren">
                                        <strong>Figuur 5</strong> Model van kwetsbaarheid van Wanberg et al. (2002)
                                        Bron: Wanberg, Hough &amp; Song, 2002
                                    </figcaption>
                                </figure>
                                <h3 class="paragrafen" style="">Referenties</h3>
                                <p>
                                    Ajzen I. (1991). The theory of planned behavior. Organizational Behavior and Human
                                    Decision Processes, 50(2), 179-211.
                                </p>
                                <p>
                                    Blonk, R.W.B. We zijn nog maar net begonnen. Oratie Tilburg University. 2018.
                                </p>
                                <p>
                                    Boermans, S., Van de Logt, J., Blonk, R.W.B. (2020). Gewogen maatwerk. Onderzoek
                                    naar implementatie van een interventie om methodisch handelen te bevorderen. Rapport
                                    voor ZonMw Programma Vakkundig aan het Werk. TNO: Leiden. Publ. Nr. 11492/060.42456
                                </p>
                                <p>
                                    Fishbein, M., &amp; Ajzen, I. (1975). Belief, attitude, intention and behavior: An
                                    introduction to theory and research. Reading, MA: Addison - Wesley.
                                </p>
                                <p>
                                    Fishbein, M. (2008). A reasoned action approach to health promotion. Medical
                                    Decision Making, 28(6), 834-844.
                                </p>
                                <p>
                                    Fishbein, M., &amp; Ajzen, I. (2010). Predicting and changing behavior: The reasoned
                                    action approach. Taylor &amp; Francis.
                                </p>
                                <p>
                                    Van Hooft, E.A.J. (2016). Motivation and self-regulation in job search: A theory of
                                    planned job-search behavior. In U.-C. Klehe, &amp; E.A.J. van Hooft (Eds.), Oxford
                                    Handbook on Job Loss and Job Search. New York: Oxford University Press.
                                </p>
                                <p>
                                    Wanberg, R.C., Hough, L.M., &amp; Song, Z. (2002). Predictive Validity of a
                                    Multidisciplinary Model of Reemployment Success. Journal of Applied Psychology, 87,
                                    1100-1120.
                                </p>
                            </section>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Bijlage 2. Basiskenmerk: Tegels Werklandschap
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <h2 class="bijlageTitel">Tegels Werklandschap</h2>
                            <p class="mb-16">
                                <strong>
                                    Hieronder worden de verschillende tegels van het werklandschap voor
                                    werkzoekenden (Eva) beschreven. Eén instrument kan meerdere tegels hebben.
                                </strong>
                            </p>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img src="@/assets/images/handleiding/bijlage2_alleTegels/tegelMelding.png"
                                                 alt="werklandschap - melding"
                                                 style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Melding</h3>
                                        <p>
                                            Een klant meldt zich bij de gemeente met het verzoek tot ondersteuning naar
                                            participatie of werk. Vaak gaat deze aanvraag gepaard met een aanvraag voor
                                            een inkomensondersteuning.
                                            Persoonlijk contact is hierbij essentieel. Klanten komen met een hulpvraag
                                            en hebben hiervoor vaak al een drempel moeten overwinnen.
                                            Het is daarom van belang dat de toegang laagdrempelig is en de professional
                                            een open houding heeft. De professional noteert basisgegevens eenmalig en
                                            registreert deze voor meervoudig gebruik. Naast de direct voorliggende vraag
                                            is de professional ook alert op andere (acute) problemen en signalen die
                                            voorkomen bij kwetsbare groepen. Bijvoorbeeld laaggeletterden of jongeren
                                            met beperkingen die de overstap maken van school naar werk. Bij dergelijke
                                            signalen neemt de professional actie of draagt de klant warm over naar het
                                            juiste loket. De professional is zorgvuldig en concreet, zodat de klant zich
                                            begrepen en goed geholpen voelt.
                                        </p>
                                    </v-col>
                                </v-row>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img
                                                src="@/assets/images/handleiding/bijlage2_alleTegels/tegelDiagnostiek.png"
                                                alt="werklandschap - diagnostiek"
                                                style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Diagnostiek</h3>
                                        <p>
                                            Bij de intake gaat de professional dieper in op de hulpvraag van de klant.
                                            De professional verdiept zich in en maakt een analyse van de informatie die
                                            al bekend is en bespreekt de huidige situatie met de klant op de
                                            verschillende levensgebieden, mogelijkheden, belemmeringen, motivatie,
                                            drijfveren, werk- en opleidingsachtergrond. Daarna stelt de professional een
                                            (eerste) klantbeeld op.
                                            Op basis van één of meer (1 tot 4) gesprekken wordt het klantbeeld gevormd
                                            en zet de professional instrumenten in voor de klant die bijvoorbeeld het
                                            vertrouwen in eigen versterken en daarmee perspectief bieden op
                                            (arbeids)participatie. Het klantbeeld is niet statisch, maar continu in
                                            ontwikkeling door bijvoorbeeld nieuwe informatie, gebeurtenissen in het
                                            leven van de klant en/of door de inzet van instrumenten. Na de inzet van een
                                            instrument evalueert de professional samen met de klant wat het effect is op
                                            het klantbeeld en past deze aan. Vervolgens wordt op basis van het nieuwe
                                            klantbeeld bepaald wat een volgend passend instrument is.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Diagnostiek
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <div class="spacer">
                                                <b>Hulpvraag verhelderen door:</b>
                                                <ul>
                                                    <li>
                                                        Uitvraag levensgebieden en persoonskenmerken, al dan niet met
                                                        behulp
                                                        van gevalideerde diagnose-instrumenten
                                                    </li>
                                                    <li>
                                                        Motivatie en onderliggende factoren, perspectief,
                                                        persoonskenmerken,
                                                        affiniteiten
                                                    </li>
                                                    <li>
                                                        Vaardigheden (basis- werknemer-, vakvaardigheden, ervaring,
                                                        opleiding etc.)
                                                    </li>
                                                    <li>Belemmeringen (fysiek, mentaal, praktisch)</li>
                                                    <li>Beeld dat de klant van zichzelf heeft</li>
                                                    <li>De urgentie van de belemmering(en)</li>
                                                    <li>Snelheid van verwacht resultaat</li>
                                                    <li>Eenvoudige/haalbare doelen en interventies</li>
                                                </ul>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <b>Klantbeeld vormen bestaande uit tenminste:</b>
                                            <ul>
                                                <li>Opstellen plan van aanpak en perspectief, weeg daarvoor de situatie
                                                    en levensgebieden
                                                </li>
                                                <li>Klantprofiel aanpassen n.a.v. interventies/ instrumenten: hebben
                                                    deze het verwachte effect?
                                                </li>
                                            </ul>
                                        </v-col>
                                    </v-row>
                                </div>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img
                                                src="@/assets/images/handleiding/bijlage2_alleTegels/tegelMaatschappelijkFit.png"
                                                alt="werklandschap - maatschappelijk fit"
                                                style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Maatschappelijk fit</h3>
                                        <p>
                                            Om naar vermogen mee te kunnen doen in de maatschappij, moeten er een aantal
                                            leefgebieden in de basis op orde zijn. De Zelfredzaamheidmatrix (ZRM) geeft
                                            een overzicht van de leefgebieden die kunnen worden uitgevraagd. De
                                            diagnostische informatie is de basis voor verdiepende gesprekken. Hierbij is
                                            er aandacht voor de basisvaardigheden, want deze zijn noodzakelijk om
                                            maatschappelijk mee te kunnen doen. Als het nodig is, wordt integrale
                                            samenwerking gezocht in het brede sociaal domein en met andere lokale
                                            maatschappelijke partners.
                                            Maatschappelijk fit kan een opstap zijn naar betaald werk, maar betaald werk
                                            is niet voor iedereen haalbaar. Dan is participatie het hoogst haalbare.
                                            Bijdragen naar vermogen vereist zorgvuldig bepalen wat haalbaar is.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Maatschappelijk fit
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <ul>
                                                <li>Basisvaardigheden (taal, rekenen, sociale vaardigheden en digitale
                                                    vaardigheden)
                                                </li>
                                                <li>Financiële situatie (schuldhulp, voedselbank, voorzieningen check),
                                                    stress-sensitieve begeleiding
                                                </li>
                                            </ul>
                                        </v-col>
                                        <v-col>
                                            <b>Inzet van participatie-instrumenten</b>
                                            <ul>
                                                <li>Vrijwilligerswerk</li>
                                                <li>Dagbesteding</li>
                                                <li>Mantelzorg</li>
                                            </ul>
                                        </v-col>
                                    </v-row>
                                </div>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img
                                                src="@/assets/images/handleiding/bijlage2_alleTegels/tegelOrientatie.png"
                                                alt="werklandschap - orientatie"
                                                style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Oriëntatie</h3>
                                        <p>
                                            Zowel de arbeidsmarkt als klanten ontwikkelen zich. Bij deze tegel
                                            onderzoekt de professional samen met de klant wat een passende (loop)baan is
                                            en wat de kansen zijn op de huidige arbeidsmarkt. Dit kan door middel van
                                            testen, stages, netwerkgesprekken, oefeningen, huiswerk (opgaven) en gebeurt
                                            vaak stapsgewijs. De uitkomst biedt een richting en perspectief om werk of
                                            een opleiding te vinden en wordt daarmee onderdeel van het klantbeeld.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Oriëntatie
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <ul>
                                                <li>Testen (competenties, capaciteiten en leervermogen, affiniteiten,
                                                    beroepen)
                                                </li>
                                                <li>Mogelijkheden van functies en bedrijven zoeken</li>
                                                <li>Inzicht in (groei)mogelijkheden die een functie/ bedrijf kan
                                                    bieden
                                                </li>
                                                <li>Begeleidingsgesprekken, benadruk dat een baan een stap is/kan zijn
                                                    naar een volgende baan
                                                </li>
                                                <li>Werkervaringsplekken/stages om het werk en de omgeving te ervaren
                                                </li>
                                            </ul>
                                        </v-col>
                                        <v-col>
                                        </v-col>
                                    </v-row>
                                </div>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img src="@/assets/images/handleiding/bijlage2_alleTegels/tegelWerkFit.png"
                                                 alt="werklandschap - werk fit"
                                                 style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Werk fit</h3>
                                        <p>
                                            Het doel van werk fit is klanten te versterken om betaald werk of een
                                            opleiding te kunnen doen.
                                            Op basis van het klantbeeld wordt gewerkt aan verschillende vaardigheden,
                                            aan het verder ontwikkelen van talenten en aan het omgaan met/oplossen van
                                            belemmeringen. Dit gebeurt vanuit een eerstvolgende haalbare stap in de
                                            ontwikkeling die de klant kan maken en kan parallel lopen met de tegels
                                            Oriënteren en Opleiden (leerwerktrajecten). Belangrijk is dat de klant deze
                                            stap succesvol behaalt, wat bijdraagt aan het versterken van het
                                            zelfvertrouwen. Waar mogelijk wordt het leren van de verschillende
                                            vaardigheden geïntegreerd met (onbetaald) werk.
                                            Voortdurend wordt het klantbeeld uitgebreid, aangepast en vastgelegd.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Werk fit
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <b>Blijvend werken aan perspectief</b>
                                            <ul>
                                                <li>Het perspectief is de motor</li>
                                            </ul>

                                            <b>Werken aan vaardigheden</b>
                                            <ul>
                                                <li>Werknemersvaardigheden</li>
                                                <li>Vakvaardigheden</li>
                                                <li>Sollicitatievaardigheden</li>
                                                <li>Presentatievaardigheden</li>
                                            </ul>
                                        </v-col>
                                        <v-col>
                                            <b>Werken aan motivatie</b>
                                            <ul>
                                                <li>Vertrouwen in eigen kunnen</li>
                                                <li>Inzet van eigen netwerk door persoon zelf</li>
                                                <li>Het belang van werk</li>
                                            </ul>

                                            <b>Werken aan volharding</b>
                                            <ul>
                                                <li>Probleemoplossend vermogen vergroten</li>
                                                <li>Werk met leerdoelen i.p.v. prestatie doelen</li>
                                            </ul>

                                            <b>Werken aan belemmeringen</b>
                                            <ul>
                                                <li>Job coaching</li>
                                                <li>Mentale en fysieke gezondheid</li>
                                                <li>Vervoersproblemen</li>
                                                <li>Opvang voor zorgtaken</li>
                                            </ul>
                                        </v-col>
                                    </v-row>
                                </div>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img src="@/assets/images/handleiding/bijlage2_alleTegels/tegelOpleiden.png"
                                                 alt="werklandschap - opleiden"
                                                 style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Opleiden</h3>
                                        <p>
                                            Taken en functies veranderen door de continu veranderende arbeidsmarkt.
                                            Hierdoor is een ‘leven lang leren’ noodzakelijk geworden. Om aan het werk te
                                            komen en blijven is (bij)scholing nodig.
                                            Opleiden kan in verschillende vormen:
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Diplomeren:</strong>
                                                het behalen van een mbo-beroepsopleiding via BBL of BOL, hbo of
                                                universiteit.
                                            </li>
                                            <li>
                                                <strong>Certificeren:</strong>
                                                het halen van een certificaat, door het behalen van een vastgesteld deel
                                                opleiding gekoppeld aan een kwalificatiedossier. Dit is altijd in
                                                samenwerking met het bedrijfsleven.
                                            </li>
                                            <li>
                                                <strong>Praktijkverklaring:</strong>
                                                erkennen van vaardigheden doordat deze zijn aangetoond in de praktijk.
                                            </li>
                                            <li>
                                                <strong>Leerwerktraject:</strong>
                                                werken en leren combineren bij een sociaal ontwikkelbedrijf.
                                            </li>
                                        </ul>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Opleiden
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <ul>
                                                <li>Certificaat (VCA, heftruck etc.)</li>
                                                <li>Kwalificatie (mbo, aangepast mbo, BBL, BOL)</li>
                                                <li>Leerwerktrajecten</li>
                                                <li>Praktijk leren in het mbo (praktijkverklaring)</li>
                                                <li>Open- en Edu-badges</li>
                                            </ul>
                                        </v-col>
                                        <v-col>
                                        </v-col>
                                    </v-row>
                                </div>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img
                                                src="@/assets/images/handleiding/bijlage2_alleTegels/tegelBemiddeling.png"
                                                alt="werklandschap - bemiddeling"
                                                style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Bemiddeling</h3>
                                        <p>
                                            De professional weegt het geheel aan diagnostische informatie, affiniteiten
                                            en competenties van de klant en koppelt deze aan vacatures en/of werkgevers.
                                            Vacatures kunnen al vacante plekken zijn, maar ook toekomstige vacatures.
                                            Toekomstige vacatures bieden meer kansen voor de doelgroep van de
                                            participatiewet, omdat er voorbereidingstijd is. Toekomstige vacatures
                                            kunnen ontstaan door groei van een bedrijf, aanpassing van bedrijfsprocessen
                                            en verwacht verloop door onder andere pensionering en opleiding van het
                                            huidige personeel. Denk hierbij ook aan de details zoals de match tussen
                                            persoonlijke aspecten/affiniteiten, werkomstandigheden en cultuur van het
                                            bedrijf. Bij hetzelfde beroep kan de functie in het ene bedrijf anders zijn
                                            dan in het andere.
                                            Belangrijk is om in affiniteiten, talenten, competenties en leervermogen te
                                            denken in plaats van in behaalde opleidingen en werkervaring. Het is daarom
                                            belangrijk dat werkgevers vacatures ook in competenties beschrijven.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Bemiddeling
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <ul>
                                                <li>Speeddates</li>
                                                <li>Verkennend gesprek</li>
                                                <li>Sollicitatiegesprek</li>
                                                <li>Ondersteunende begeleiding tijdens gesprek</li>
                                            </ul>
                                        </v-col>
                                        <v-col>
                                            <b>Werkgevers Service Punt (WSP)</b>
                                            <ul>
                                                <li>Wederzijdse aansluiting tussen coaching en activiteiten WSP</li>
                                                <li>Relatie opbouw met private partijen</li>
                                                <li>Vraaggericht werken door het aanbieden van passende kandidaten</li>
                                                <li>Aanbodgericht werken op basis van goede relaties en nazorg aan
                                                    werkgevers
                                                </li>
                                            </ul>
                                        </v-col>
                                    </v-row>
                                </div>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img
                                                src="@/assets/images/handleiding/bijlage2_alleTegels/tegelPlaatsing.png"
                                                alt="werklandschap - plaatsing"
                                                style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Plaatsing</h3>
                                        <p>
                                            De professional bespreekt op basis van de mogelijkheden van de klant en de
                                            plaatsingsafspraken met de werkgever welke ondersteunde voorzieningen zorgen
                                            voor de beste kans van slagen op een duurzame match. De professional heeft
                                            hierin een informerende, adviserende en bemiddelende rol. De professional
                                            bespreekt daarbij de valkuilen en afbreekrisico’s met de werkgever en de
                                            klant. Zij bespreken wat nodig is om de plaatsing duurzaam te laten slagen
                                            en maken afspraken over de nazorg.
                                            Belangrijk aandachtspunt is om de klant zoveel mogelijk te betrekken bij de
                                            afspraken.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Plaatsing
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <ul>
                                                <li>Praktijkroute</li>
                                                <li>Loonkostensubsidie (LKS)</li>
                                                <li>Werkplekaanpassing</li>
                                                <li>Jobcoaching</li>
                                            </ul>
                                        </v-col>
                                        <v-col>
                                        </v-col>
                                    </v-row>
                                </div>
                            </section>
                            <hr/>
                            <section class="tile">
                                <v-row>
                                    <v-col cols="4">
                                        <figure>
                                            <img src="@/assets/images/handleiding/bijlage2_alleTegels/tegelNazorg.png"
                                                 alt="werklandschap - nazorg"
                                                 style="object-fit: contain; width: 100%; height: 100%;"
                                            />
                                        </figure>
                                    </v-col>
                                    <v-col cols="8">
                                        <h3 class="tegelItem">Nazorg</h3>
                                        <p>
                                            Om de kans te vergroten dat de klant aan het werk blijft, is het van belang
                                            dat er nazorg wordt geboden als dat nodig is. Dit kan gaan om ondersteuning
                                            aan de klant, de werkgever en/of collega’s. De duur, intensiteit en inhoud
                                            zijn afhankelijk van de behoefte. Dit kan variëren van monitoring door
                                            nabellen tot intensieve structurele begeleiding.
                                            Sommigen gemeenten doen dit zelf, anderen kopen jobcoaching en/of andere
                                            ondersteuning in.
                                            Voor werkgevers is de sleutel voor duurzame plaatsingen continuïteit, één
                                            aanspreekpunt, weinig wisselingen in de contactpersoon en snelle (re)actie
                                            in de nazorg.
                                            Nazorg betekent ook tijdig handelen wanneer de plaatsing niet succesvol is,
                                            op zijn einde loopt of wanneer de klant de werkplek ontgroeit.
                                        </p>
                                    </v-col>
                                </v-row>
                                <div class="voorbeeld">
                                    <header>
                                        Voorbeelden van instrumenten Nazorg
                                    </header>
                                    <v-row>
                                        <v-col>
                                            <ul>
                                                <li>Bevorderen duurzame inzetbaarheid (DI)</li>
                                                <li>Jobcoaching</li>
                                                <li>Ondersteuning werkgever</li>
                                                <li>Instrumenten DI</li>
                                            </ul>
                                        </v-col>
                                        <v-col></v-col>
                                    </v-row>
                                </div>
                            </section>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Bijlage 3. Basiskenmerk: Klantkenmerken
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <h2 class="bijlageTitel">Klantkenmerken</h2>
                            <p class="mb-16">
                                <b>
                                    De klantkenmerken zijn gebaseerd op het IGM en het Model van Wanberg (zie Bijlage 1)
                                    en praktisch vertaald in samenwerking met de deelnemende gemeenten. Hieronder worden
                                    de verschillende klantkenmerken beschreven. Eén instrument kan meerdere
                                    klantkenmerken hebben.
                                </b>
                            </p>

                            <ol class="klantkenmerken">
                                <li class="klantkenmerkItem">
                                    Beroepskeuze, beroepsvoorkeur en beroepsoriëntatie
                                    <p>
                                        Dit kenmerk richt zich op het ondersteunen van de klant bij de beroepskeuze en
                                        een
                                        oriëntatie op de arbeidsmarkt. Denk bijvoorbeeld aan een bedrijfsbezoek,
                                        snuffelstage, beroepskeuze-/affiniteitentest of branchegerichte leerlijnen.
                                    </p>
                                    <div class="voorbeeld">
                                        <header>
                                            Voorbeelden van instrumenten
                                        </header>
                                        <v-row>
                                            <v-col>
                                                <ul>
                                                    <li>Competentietesten</li>
                                                    <li>Capaciteitentesten</li>
                                                    <li>Loopbaancoaching</li>
                                                </ul>
                                            </v-col>
                                            <v-col>
                                                <ul>
                                                    <li>Training kennis- en sollicitatiecentrum</li>
                                                    <li>In-Werkweek</li>
                                                    <li>Snuffelstage via uitzendbureau</li>
                                                </ul>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </li>

                                <li class="klantkenmerkItem">
                                    Willen en geloof in eigen kunnen
                                    <p>
                                        Naar werk willen zoeken of willen participeren is de eerste stap naar werk of
                                        participatie. Volgens het integratieve gedragsmodel (zie Figuur 3 in de bijlage)
                                        wordt willen of motivatie beïnvloed door drie aspecten. (1) De houding, oftewel
                                        de verwachte voor- en nadelen van werken / participeren (‘wat levert het me
                                        op?’). (2) Sociale norm, oftewel wat vinden anderen wat je moet doen en wat doen
                                        anderen in mijn omgeving. Als veel anderen niet werken in iemands omgeving dan
                                        lijkt dat de norm. (3) Geloof in eigen kunnen, namelijk dat je denkt dat je het
                                        kunt doen. Je hebt er vertrouwen in dat je werk vindt of zal participeren. De
                                        motivatie kan meer uit iemand zelf komen, zoals leuk werk vinden (‘intrinsiek’)
                                        of meer van buitenaf, zoals geld verdienen met werk (‘extrinsiek’). Mocht een
                                        klant bijvoorbeeld onvoldoende motivatie hebben om werk te vinden, dan kan
                                        deelname aan een werkervaringsplek bijvoor- beeld positieve invloed hebben omdat
                                        de klant anderen ziet werken en het ‘geloof in eigen kunnen’ wordt versterkt.
                                    </p>
                                    <div class="voorbeeld">
                                        <header>
                                            Voorbeelden van instrumenten
                                        </header>
                                        <v-row>
                                            <v-col>
                                                <ul>
                                                    <li>Proefplaatsing</li>
                                                    <li>Empowerment training</li>
                                                    <li>Interne/externe jobcoaching</li>
                                                </ul>
                                            </v-col>
                                            <v-col>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </li>
                                <li class="klantkenmerkItem">
                                    Sociale steun en netwerk
                                    <p>
                                        Het sociale netwerk, zoals familie, vrienden, kennissen, maar ook de
                                        begeleidende professional(s), kan een grote rol spelen bij het ondersteunen van
                                        een klant bij het zoeken, vinden en behouden van werk. Er zijn klanten waar een
                                        sociaal netwerk afwezig is en die in een isolement verkeren. Het is goed om in
                                        kaart te brengen wie in de omgeving aanwezig is om de klant te ondersteunen.
                                        Onder dit kenmerk kunnen dus instrumenten worden geplaatst die klanten
                                        ondersteunen bij het verstevigen of inzetten van het sociale netwerk en die de
                                        klant stimuleren om deel te nemen aan de maatschappij. Daarbij kan een
                                        onderscheid worden gemaakt tussen praktische steun en emotionele steun. Bij
                                        praktische steun kan gedacht worden aan alle vormen van hulp/ondersteuning
                                        vanuit het sociale netwerk op praktisch gebied. Denk bijvoorbeeld aan
                                        hulp/ondersteuning bij het vinden van advertenties, het geven van tips voor
                                        schrijven van sollicitatiebrieven, of de klant wegbrengen naar een
                                        sollicitatiegesprek. Emotionele steun is dat mensen in het sociale netwerk
                                        iemand aanmoedigen om werk te vinden, complimenten geven als dingen goed gaan,
                                        maar ook ondersteunen bij het uiten van verdriet en teleurstelling.
                                    </p>
                                    <div class="voorbeeld">
                                        <header>
                                            Voorbeelden van instrumenten
                                        </header>
                                        <v-row>
                                            <v-col>
                                                <ul>
                                                    <li>Sociale kaart</li>
                                                    <li>Training netwerken</li>
                                                    <li>Jobcoaching</li>
                                                </ul>
                                            </v-col>
                                            <v-col>
                                                <ul>
                                                    <li>Empowerment training</li>
                                                    <li>Sociaal casemanager</li>
                                                    <li>Meehelpen in een buurthuis</li>
                                                </ul>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </li>

                                <li class="klantkenmerkItem">
                                    Zelfregie/zelfsturing
                                    <p>
                                        Dit kenmerk is van belang op het moment dat de klant moeite heeft om richting te
                                        geven aan zijn of haar leven, of om doelen te stellen en deze doelen vervolgens
                                        te bereiken. Hierbij wordt rekening gehouden met de eigen sterke en zwakke
                                        kanten, interesses, waarden en ambities. Om de gestelde doelen te bereiken is
                                        het belangrijk om te kunnen reflecteren op het eigen gedrag, om te kunnen gaan
                                        met feedback, om te kunnen gaan met teleurstellingen en tegenslagen en in staat
                                        zijn om een alternatief plan te bedenken om je doel te bereiken. Instrumenten
                                        gericht op bijvoorbeeld het stellen van doelen, reflectie op het eigen gedrag en
                                        omgaan met bepaalde teleurstellingen en tegenslagen kunnen de klant ondersteunen
                                        om meer richting te geven aan zijn of haar (werk)leven.
                                    </p>
                                    <div class="voorbeeld">
                                        <header>
                                            Voorbeelden van instrumenten
                                        </header>
                                        <v-row>
                                            <v-col>
                                                <ul>
                                                    <li>Coaching</li>
                                                    <li>Empowermenttraining</li>
                                                    <li>Workshop daklozen</li>
                                                </ul>
                                            </v-col>
                                            <v-col>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </li>

                                <li class="klantkenmerkItem">
                                    Aandachtspunten klant
                                    <ol>
                                        <li>
                                            Psychosociale aandachtspunten
                                            <p>
                                                Psychische aandachtspunten hebben te maken met de invloed van iemands
                                                gevoelens en gedachten op zijn of haar functioneren. Men kan negatieve
                                                gevoelens ervaren (bijvoorbeeld somberheid, boosheid of angst, een
                                                omslaand humeur), minder goed cognitieve taken uitvoeren (bijvoorbeeld
                                                slechter concentreren, onthouden en denken), slechter omgaan met stress
                                                (bijvoorbeeld minder goed prikkels aan kunnen), te maken krijgen met
                                                dwanggedachten en minder goed plannen en organiseren. Sociale
                                                aandachtspunten gaan over hoe iemand met andere mensen of instanties
                                                omgaat. Bij psychosociale aandachtspunten spelen zowel de psychische
                                                als sociale aandachtspunten die elkaar ook kunnen versterken.
                                            </p>
                                            <div class="voorbeeld">
                                                <header>
                                                    Voorbeelden van instrumenten
                                                </header>
                                                <v-row>
                                                    <v-col>
                                                        <ul>
                                                            <li>Verdiepende diagnose-instrumenten</li>
                                                            <li>Psychodiagnostiek</li>
                                                            <li>Training samenwerken</li>
                                                        </ul>
                                                    </v-col>
                                                    <v-col>
                                                        <ul>
                                                            <li>(Wandel)coaching</li>
                                                            <li>Sociaal casemanager</li>
                                                            <li>Mindfullnesstraining</li>
                                                        </ul>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </li>
                                        <li>
                                            Lichamelijke aandachtspunten
                                            <p>
                                                Lichamelijke aandachtspunten gaan over beperkingen op fysiek vlak. Denk
                                                bijvoorbeeld aan beperkingen op het gebied van structurele, frequente of
                                                langdurige belastbaarheid. Of beperkingen op het gebied van reiken,
                                                buigen, duwen of trekken, tillen of dragen, lopen, zitten of staan,
                                                lopen, werken op hoogte; zicht en horen; fijne motoriek, fijn werk
                                                kunnen doen en handelingstempo; snelheid van werken of handelen. Ook
                                                kunnen er beperkingen zijn op het gebied van leefstijl. Denk
                                                bijvoorbeeld aan een gebrek aan beweging, gezondheidsproblemen door
                                                roken, slechte voeding, weinig slapen, of veel stress.
                                            </p>
                                            <div class="voorbeeld">
                                                <header>
                                                    Voorbeelden van instrumenten
                                                </header>
                                                <v-row>
                                                    <v-col>
                                                        <ul>
                                                            <li>Belastbaarheidsonderzoek/keuring</li>
                                                            <li>Werkplekaanpassing</li>
                                                            <li>Leer-werktrajecten</li>
                                                        </ul>
                                                    </v-col>
                                                    <v-col>
                                                        <ul>
                                                            <li>Inzet hulpmiddelen</li>
                                                            <li>Arbo-advies</li>
                                                            <li>Arbeidsdeskundig advies</li>
                                                        </ul>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </li>
                                        <li>
                                            Praktische aandachtspunten
                                            <p>
                                                Praktische aandachtspunten gaan over beperkingen met betrekking tot de
                                                praktische voorzieningen waar een klant over beschikt. Denk bijvoorbeeld
                                                aan: kinderopvang, mantelzorg, mobiliteit/vervoer, schulden,
                                                justitie/detentie, huisvesting/woonsituatie (bijvoorbeeld dakloosheid,
                                                dreigende huisuitzetting, ernstige geluid- of stankoverlast,
                                                onveiligheid, afsluiting van gas en licht, niet meer in je huis kunnen
                                                wonen omdat aanpassingen nodig zijn), cultuuraspecten, en praktische
                                                gevolgen van verslaving (alcoholgebruik, drugsgebruik, gok-, game- en
                                                koopverslaving).
                                            </p>
                                            <div class="voorbeeld">
                                                <header>
                                                    Voorbeelden van instrumenten
                                                </header>
                                                <v-row>
                                                    <v-col>
                                                        <ul>
                                                            <li>Uitkomen met inkomen (STMR)</li>
                                                            <li>Schuldhulpverlening</li>
                                                            <li>Inkomenscoach training on the job</li>
                                                            <li>Budgetbeheer</li>
                                                            <li>Training ‘Jij en je zorgverzekering’</li>
                                                        </ul>
                                                    </v-col>
                                                    <v-col>
                                                        <ul>
                                                            <li>Werknemerscheck</li>
                                                            <li>Sociaal casemanager</li>
                                                            <li>Sociaal raadsman/vrouw</li>
                                                            <li>Makelaar kinderopvang</li>
                                                            <li>Verstrekking van fiets of laptop</li>
                                                        </ul>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </li>
                                    </ol>
                                </li>
                                <li class="klantkenmerkItem">
                                    Basisvaardigheden
                                    <p>
                                        Dit zijn Nederlands lezen en schrijven; Nederlands kunnen verstaan en spreken
                                        (A1
                                        (basis), A2, B1, B2 etc.); rekenen; digitale vaardigheden. Gaat onder meer over
                                        laaggeletterdheid.
                                    </p>
                                    <div class="voorbeeld">
                                        <header>
                                            Voorbeelden van instrumenten
                                        </header>
                                        <v-row>
                                            <v-col>
                                                <ul>
                                                    <li>Taalondersteuning voor mensen met een lage leerbaarheid</li>
                                                    <li>Flankerende taaltraining</li>
                                                    <li>Leren communiceren in een groep</li>
                                                </ul>
                                            </v-col>
                                            <v-col>
                                                <ul>
                                                    <li>E-learning gericht op het versterken van basisvaardigheden</li>
                                                    <li>Digitale vaardigheidstraining</li>
                                                </ul>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </li>

                                <li class="klantkenmerkItem">
                                    Sociale en culturele vaardigheden
                                    <p>
                                        Relevante sociale vaardigheden zoals communiceren, samenwerken, overleggen en
                                        afspraken nakomen. Netwerken en relaties kunnen opbouwen. Culturele vaardigheden
                                        gaan over het effectief kunnen leren, werken en leven met mensen van
                                        verschillende etnische, sociale en culturele achtergronden (“burgerschap”).
                                    </p>
                                    <div class="voorbeeld">
                                        <header>
                                            Voorbeelden van instrumenten
                                        </header>
                                        <v-row>
                                            <v-col>
                                                <ul>
                                                    <li>Training voor statushouders over werken in Nederland</li>
                                                    <li>Sociale vaardigheidstraining</li>
                                                    <li>Vrijwilligerswerk</li>
                                                </ul>
                                            </v-col>
                                            <v-col>
                                                <ul>
                                                    <li>Werkervaringsplek</li>
                                                    <li>Wonen in Nederland</li>
                                                    <li>Fietslessen</li>
                                                </ul>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </li>

                                <li class="klantkenmerkItem">
                                    Werknemersvaardigheden
                                    <ol>
                                        <li>
                                            Basis werknemersvaardigheden
                                            <p>
                                                Relevante vaardigheden als werktempo, arbeidsritme, gevoel voor
                                                arbeidsverhoudingen, doorzettingsvermogen, nauwkeurigheid,
                                                zelfstandigheid, verzorgd uiterlijk.
                                            </p>
                                            <div class="voorbeeld">
                                                <header>
                                                    Voorbeelden van instrumenten
                                                </header>
                                                <v-row>
                                                    <v-col>
                                                        <ul>
                                                            <li>Training werknemersvaardigheden</li>
                                                            <li>Leer-werktrajecten</li>
                                                        </ul>
                                                    </v-col>
                                                    <v-col>
                                                        <ul>
                                                            <li>Werkervaringsplek</li>
                                                            <li>Coaching</li>
                                                        </ul>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </li>
                                        <li>
                                            Toekomstige werknemersvaardigheden
                                            <p>
                                                Zogenaamde ‘21st century skills’ worden steeds belangrijker. Het gaat om
                                                vaardigheden als kritisch denken, creatief denken, probleemoplossend
                                                vermogen, informatievaardigheden en mediawijsheid.
                                            </p>
                                            <div class="voorbeeld">
                                                <header>
                                                    Voorbeelden van instrumenten
                                                </header>
                                                <v-row>
                                                    <v-col>
                                                        <ul>
                                                            <li>
                                                                Training gericht op het versterken van toekomstige
                                                                werknemersvaardigheden
                                                            </li>
                                                        </ul>
                                                    </v-col>
                                                    <v-col>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </li>
                                    </ol>
                                </li>
                                <li class="klantkenmerkItem">
                                    Sollicitatievaardigheden
                                    <p>
                                        Sollicitatievaardigheden gaan over de vaardigheden die een klant kunnen helpen
                                        bij het vinden van (on)betaald werk. Denk bijvoorbeeld aan vaardigheden op het
                                        gebied van: werk zoeken, gebruik van het eigen netwerk en de inzet van sociale
                                        media, het opstellen van het cv en motivatiebrieven en het voeren van een
                                        sollicitatiegesprek. Daarnaast kan er worden gedacht aan de persoonlijke
                                        presentatie van een werkzoekende klant, de persoonlijke hygiëne, kledingkeuze en
                                        haardracht.
                                    </p>
                                    <div class="voorbeeld">
                                        <header>
                                            Voorbeelden van instrumenten
                                        </header>
                                        <v-row>
                                            <v-col>
                                                <ul>
                                                    <li>Basistraining sollicitatievaardigheden</li>
                                                    <li>E-learning sollicitatievaardigheden</li>
                                                    <li>CV-training (individueel/in een groep)</li>
                                                </ul>
                                            </v-col>
                                            <v-col>
                                                <ul>
                                                    <li>Video pitch</li>
                                                    <li>Training ‘Presenteer jezelf’</li>
                                                </ul>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </li>

                                <li class="klantkenmerkItem">
                                    Vakvaardigheden
                                    <ol>
                                        <li>
                                            Diplomeren, certificeren en praktijkverklaring
                                            <ul>
                                                <li>
                                                    Diplomeren; het behalen van een mbo-opleiding via BBL of BOL, hbo of
                                                    universiteit.
                                                </li>
                                                <li>
                                                    Certificeren; het halen van een (branchegericht)certificaat, door
                                                    het
                                                    behalen van een vastgesteld deel opleiding gekoppeld aan een
                                                    kwalificatiedossier. Dit is altijd in samenwerking met het
                                                    bedrijfsleven.
                                                </li>
                                                <li>
                                                    Praktijkverklaring; erkennen van vaardigheden doordat deze zijn
                                                    aangetoond in de praktijk.
                                                </li>
                                                <li>
                                                    Leren leidinggeven en veilig werken.
                                                </li>
                                            </ul>
                                            <div class="voorbeeld">
                                                <header>
                                                    Voorbeelden van instrumenten
                                                </header>
                                                <v-row>
                                                    <v-col>
                                                        <ul>
                                                            <li>VCA-cursus</li>
                                                            <li>EVC</li>
                                                            <li>Heftruckdiploma</li>
                                                        </ul>
                                                    </v-col>
                                                    <v-col>
                                                        <ul>
                                                            <li>Lasdiploma</li>
                                                            <li>Inburgering</li>
                                                            <li>Opleiding ICT</li>
                                                        </ul>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </li>

                                        <li>
                                            Werkervaring
                                            <p>
                                                Dit kenmerk richt zich erop dat de klant (meer branchegerichte)
                                                werkervaring opdoet. Ook zonder een diploma kan men namelijk
                                                competenties verwerven. Werkervaring kan ook via vrijwilligerswerk
                                                verkregen zijn.
                                            </p>
                                            <div class="voorbeeld">
                                                <header>
                                                    Voorbeelden van instrumenten
                                                </header>
                                                <v-row>
                                                    <v-col>
                                                        <ul>
                                                            <li>
                                                                Leer-werktrajecten/werkervaringsplekken in verschillende
                                                                branches
                                                            </li>
                                                        </ul>
                                                    </v-col>
                                                    <v-col>
                                                        <ul>
                                                            <li>Vrijwilligerswerk</li>
                                                            <li>Stages</li>
                                                        </ul>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Bijlage 4. Klantkenmerken geplot op het integratieve gedragsmodel
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <h2 class="bijlageTitel">Klantkenmerken geplot op het integratieve gedragsmodel</h2>
                            <p>
                                <strong>
                                    In onderstaande figuur zijn de veertien klantkenmerken (roze vlakken) geplot op de
                                    onderdelen van
                                    het Integratieve Gedragsmodel.
                                </strong>
                            </p>
                            <figure>
                                <img src="@/assets/images/handleiding/bijlage4_IGM_en_kkm.png"
                                     alt="het Integratieve Gedragsmodel en klantkenmerken"
                                     style="object-fit: contain; width: 100%; height: 100%;"
                                />
                            </figure>
                        </ExpansionPanelContent>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelHeader>
                            Bijlage 5. Praktische kenmerken
                        </ExpansionPanelHeader>
                        <ExpansionPanelContent>
                            <p>
                                <b>
                                    Een instrument wordt met onderstaande praktische kenmerken beschreven. De kenmerken
                                    uitvoeringsvorm, werkwijze, onderscheidende aanpak, groep/individueel, toelichting
                                    doelgroep, voorwaarden deelname, samenwerkingspartners en aanvullende informatie
                                    zijn gegroepeerd te zien onder het kopje ‘Beschrijving’ van het instrument in Eva.
                                </b>
                            </p>

                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Naam instrument</header>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Samenvatting</header>
                                <p class="praktischKenmerkBeschrijving">
                                    Een beknopte samenvatting van het doel en de werkwijze van het instrument
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Doel</header>
                                <p>Een korte beschrijving van het doel van het instrument</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Uitvoeringsvorm</header>
                                <ul>
                                    <li>Onderzoek</li>
                                    <li>Vragenlijst</li>
                                    <li>e-Learning (webinar, etc.)</li>
                                    <li>Workshop</li>
                                    <li>Training</li>
                                    <li>Coaching</li>
                                    <li>Werk/Stage</li>
                                    <li>Anders [...]</li>
                                </ul>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Werkwijze</header>
                                <p>
                                    De manier waarop het instrument wordt uitgevoerd; de activiteiten/werkzaamheden
                                    waaruit een instrument
                                    bestaat en de onderwerpen/modules die hierbij aan bod komen.
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Onderscheidende aanpak</header>
                                <p>Wat maakt het instrument onderscheidend?</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Groep/individueel</header>
                                <p>
                                    Wordt het instrument in een groep en/of individueel aangeboden? Beide opties zijn
                                    mogelijk voor één
                                    instrument.
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Toelichting doelgroep</header>
                                <p>
                                    Een nadere beschrijving van de deelnemende doelgroep(en).
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Voorwaarden deelname</header>
                                <p>
                                    Een beschrijving van de voorwaarden waaraan een klant moet voldoen om deel te nemen
                                    en/of een instrument
                                    te gebruiken zoals:
                                </p>
                                <ul>
                                    <li>Leeftijd</li>
                                    <li>Basis- en communicatievaardigheden</li>
                                    <li>Opleidingsachtergrond</li>
                                    <li>Werkervaring</li>
                                    <li>Motivatie</li>
                                    <li>Fysieke/mentale belastbaarheid</li>
                                    <li>Lichamelijke/geestelijke gezondheid</li>
                                    <li>Praktische voorwaarden</li>
                                </ul>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Samenwerkingspartners</header>
                                <p>Relevante partners die samenwerken met de aanbieder en/of uitvoeringsorganisatie.</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Aanvullende informatie</header>
                                <p>Eventuele bijzonderheden/aanvullende informatie.</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Aanbieder</header>
                                <p>
                                    Een beschrijving van de aanbiedende partij/leverancier van het instrument.
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Locatie(s) uitvoering</header>
                                <p>
                                    Waar het instrument wordt uitgevoerd: bij de aanbieder, de werkgever, de gemeente,
                                    de klant thuis, of een ander adres. Er kan een toelichting te zien zijn op de
                                    locatie.
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Werkafspraken</header>
                                <p>
                                    De (werk)afspraken rondom het gebruik van het instrument met gemeenten/aanbieders.
                                    Bijvoorbeeld: Wie nodigt wie uit? Hoe verloopt het evaluatieproces? Wie begeleidt de
                                    klant? Hoe houdt de begeleider de klant op de hoogte?
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Aanmeldinstructies</header>
                                <p>
                                    Beschrijving van de aanmeldinstructies zodat de professional weet hoe een klant
                                    aangemeld kan worden bij de aanbieder (bijvoorbeeld middels een mail of code in het
                                    systeem). Indien een instrument een registratiecode heeft staat deze bij het
                                    instrument.
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Contactpersoon</header>
                                <p>
                                    De contactgegevens (naam, telefoon, e-mail) van interne of externe contactpersonen.
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Doelgroep</header>
                                <p>
                                    Een instrument kan gericht zijn op (een) specifieke doelgroep(en). Er zijn acht
                                    standaard doelgroe- pen
                                    (zie hieronder). Daarnaast kan een gemeente/samenwerkingsverband maximaal acht extra
                                    doelgroepen hebben
                                    toegevoegd. De voorgedefinieerde doelgroepen zijn:
                                </p>
                                <ul>
                                    <li>Jongeren tot 27 jaar</li>
                                    <li>50-plussers</li>
                                    <li>(vermoedelijk) verstandelijke beperking (IQ van 50-85)</li>
                                    <li>(vermoedelijk) arbeidsbeperkten</li>
                                    <li>Statushouders</li>
                                    <li>Migranten</li>
                                    <li>Ondernemers</li>
                                    <li>Dak- en thuislozen</li>
                                </ul>
                                <p>
                                    <strong>Belangrijk!</strong> Er is geen doelgroep ‘algemeen’. Hiervoor is bewust
                                    gekozen omdat de term ‘alge- meen’ verwarrend kan werken; is een algemeen instrument
                                    geschikt voor iedereen ínclusief alle doelgroepen, of juist geschikt voor iedereen
                                    behálve de specifieke doelgroepen? Om dit misverstand te voorkomen is geen doelgroep
                                    algemeen aangemaakt. Een instrument zonder doelgroep is dan geschikt voor iedereen.
                                    Een instrument met doelgroep is óók geschikt voor de desbetreffende doelgroep.
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Intensiteit</header>
                                <p>De intensiteit van het instrument in hoeveelheid uur per week.</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Toelichting intensiteit</header>
                                <p>Eventuele toelichting op de intensiteit.</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Totale Duur</header>
                                <p>
                                    Totale duur van de uitvoering van het instrument in
                                    uur/dag(en)/we(e)k(en)/maand(en).
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Toelichting duur</header>
                                <p>Eventuele toelichting op de duur.</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Totale Kosten</header>
                                <p>De totale kosten van het instrument in euro.</p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Toelichting kosten</header>
                                <p>
                                    Eventuele toelichting op de kosten. Bijvoorbeeld qua kosten: Betreft het een
                                    indicatie? Verschillen de kosten per traject? Zijn de kosten inclusief of exclusief
                                    btw?
                                </p>
                            </section>
                            <hr>
                            <section class="praktischKenmerk">
                                <header class="praktischKenmerkNaam">Aanvullende inhoud</header>
                                <p>
                                    Hier kunnen linkjes, bestanden en filmpjes zijn toegevoegd. ‘Links’ zijn
                                    bijvoorbeeld verwijzingen naar externe websites, aan ‘downloads’ kunnen bijvoorbeeld
                                    pdf-bestanden zijn toegevoegd en via ‘video’s’ kan bijvoorbeeld YouTube filmpje zijn
                                    toegevoegd.
                                </p>
                            </section>
                        </ExpansionPanelContent>
                    </ExpansionPanel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss" scoped>
.handleiding-accordion {
  .v-expansion-panel:before {
    box-shadow: none;
  }

  .v-expansion-panel--active {
    border-top: 1px solid lightgrey;
  }

  .v-icon {
    margin-top: -3px;
  }
}

.definitie {
  padding-left: 32px;
  padding-right: 32px;
  font-weight: bold;
}

.info {
  color: var(--primary);
}

figure {
  margin-top: 24px;
  margin-bottom: 24px;

  figcaption {
    padding: 8px 0;
    font-size: 0.9em;
  }
}

#disclaimer {
  border-width: 1px;
  border-style: solid;
  border-color: var(--tertiary);

  header {
    background-color: var(--tertiary);
    color: var(--white);
    padding: 4px 8px;
    font-weight: bold;
  }

  p {
    padding: 10px 8px 0;
  }
}

.pinkReference {
  color: #ED6D91;
  font-weight: bold;
}

.voorbeeld {
  margin-top: 24px;
  padding: 24px;
  background-color: var(--gray-light);
  font-size: 0.9em;

  > header {
    color: var(--text-secondary);
    margin-bottom: 8px;
    font-weight: bold;
  }

  ul {
    list-style-position: outside;
    list-style-type: disc;
    margin: 0 0 16px;
  }

  b, li {
    color: var(--primary);
  }
}

hr {
  border-width: 1px 0 0;
  border-color: var(--primary);
}

.tile {
  margin-top: 32px;
  margin-bottom: 32px;

  figure {
    margin: 0;
    padding: 0 16px 0 0;
  }
}

ol {
  list-style-position: inside;
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0 !important;

  & > li {
    //display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
}

ol > li:before {
  content: counters(item, ".") ". ";
  //display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

.klantkenmerkItem {
  margin-top: 36px;
  font-family: "Avenir-Next-LT-Pro-Bold_5182", Arial, Verdana, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: var(--secondary);

  & > ol > li {
    margin-top: 24px;
    font-family: "Avenir-Next-LT-Pro-Bold_5182", Arial, Verdana, sans-serif;
    color: var(--primary-lighten);
  }
}

.praktischKenmerk {
  margin: 8px 0 16px;

  ul {
    margin-left: 0;
  }
}

</style>

<script>

import ExpansionPanel from '@/components/styled/ExpansionPanel/ExpansionPanel'
import ExpansionPanelHeader from '@/components/styled/ExpansionPanel/ExpansionPanelHeader'
import ExpansionPanelContent from '@/components/styled/ExpansionPanel/ExpansionPanelContent'

export default {
  name: 'Handreiking',
  components: {
    ExpansionPanelContent,
    ExpansionPanelHeader,
    ExpansionPanel
  }
}
</script>
