import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

export default {
  namespaced: true,
  state: () => {
    return {
      Zoeken: '',
      Tegel: [],
      Thema: [],
      Doelgroep: [],
      ClientCharacteristic: [],
      Owners: [],
      Provider: [],
      Reach: [],
      Areas: [],
      Townships: []
    }
  },
  mutations,
  actions,
  getters
}
