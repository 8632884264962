import { Auth } from 'aws-amplify'

export const setAuthState = ({ commit, state }, authState) => {
  if (!state.states.includes(authState)) {
    // console.error('invalid state', authState);
  }
  commit('SET_AUTH_STATE', authState)
}
export const resetAuthState = ({ commit }) => commit('SET_AUTH_STATE', undefined)

export const setAuthFlowUser = ({ commit }, user) => commit('SET_AUTH_FLOW_USER', user)

export const refreshUser = ({ commit }) => {
  commit('SET_LOADING', true)
  return Auth.currentAuthenticatedUser()
    .catch(() => {
      commit('SET_AUTH_STATE', 'signed-out')
      commit('SET_USER', null)
    })
    .then(user => {
      commit('SET_USER', user)
    })
    .finally(() => commit('SET_LOADING', false))
}

export const handleAuthEvent = ({ dispatch }, payload) => {
  if (payload.event === 'tokenRefresh_failure') {
    // Couldn't refresh the token.
    // Return to prevent a loop
    return
  }

  dispatch('refreshUser')

  // switch (payload.event) {
  //     case 'signIn':
  //         // ?
  //         break;
  //     case 'signUp':
  //         // ?
  //         break;
  //     case 'signOut':
  //         // ?
  //         break;
  //     case 'signIn_failure':
  //         // ?
  //         break;
  //     case 'tokenRefresh':
  //         // ?
  //         break;
  //     case 'configured':
  //         // ?
  // }
}
