<template>
    <v-expansion-panel-header dense>
        <slot />
    </v-expansion-panel-header>
</template>

<script>
export default {
  name: 'ExpansionPanelSidebarHeader'
}
</script>

<style lang="scss" scoped>
    .v-expansion-panel-header {
        font-size: 20px;
        font-weight: bold;
        padding: 8px 24px;
        opacity: 0.6;
        min-height: 42px;
    }

    .v-expansion-panel-header--active,
    .v-expansion-panel-header--active .v-icon {
        opacity: 1;
        color: var(--primary) !important;
    }
</style>
