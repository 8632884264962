<template>
    <v-expansion-panel>
        <slot />
    </v-expansion-panel>
</template>

<script>
export default {
  name: 'ExpansionPanel'
}
</script>

<style lang="scss" scoped>
    .v-expansion-panel {
        &:before {
             box-shadow: none;
        }
    }

    .v-expansion-panel-content__wrap {
        padding: 0;
    }

    .v-expansion-panel-header {
        font-size: 20px;
        font-weight: bold;
        padding: 0;
        opacity: 0.6;
        min-height: 42px;
    }

    .v-expansion-panel-header--active,
    .v-expansion-panel-header--active .v-icon {
        opacity: 1;
        color: var(--v-primary-base) !important;
    }
</style>
