import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import general from './vuetify/general'
// import environmentTheme from './vuetify/environment-theme'

Vue.use(Vuetify)

export default new Vuetify({
  theme: { disable: true }
  // theme: {
  //     themes: {
  //         light: environmentTheme,
  //     },
  //     options: {
  //         customProperties: true
  //     }
  // }
})
