import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import aanbod from './modules/aanbod'
import auth from './modules/auth'
import elastic from './modules/elastic'
import environment from './modules/environment'
import filters from './modules/filters'
import version from './modules/version'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['aanbod', 'elastic', 'environment', 'filters', 'version']
})

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['auth']
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    aanbod,
    auth,
    elastic,
    environment,
    filters,
    version
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin]
})
