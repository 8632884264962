import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import svgJs from './plugins/vueSvgPlugin'
import ReactiveSearch from '@appbaseio/reactivesearch-vue'
import VueYouTubeEmbed from 'vue-youtube-embed'
import moment from 'moment'

import vueCurrencyConfig from './plugins/vueCurrencyFilter'
import vueCurrencyFilter from 'vue-currency-filter'
import Amplify, { Hub } from 'aws-amplify'
import awsconfig from '../aws.config'
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader'
import '@aws-amplify/ui-vue'

Amplify.configure(awsconfig)
applyPolyfills().then(() => {
  defineCustomElements(window)
})
Hub.listen('auth', (data) => {
  const { payload } = data
  store.dispatch('auth/handleAuthEvent', payload)
})

Vue.use(VueYouTubeEmbed)
Vue.use(svgJs)
Vue.use(ReactiveSearch)
Vue.use(vueCurrencyFilter, vueCurrencyConfig)

Vue.config.productionTip = false

Vue.filter('date', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
})

Vue.filter('time', function (value) {
  if (value) {
    return moment(String(value)).format('H:mm')
  }
})

Vue.filter('dateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY H:mm')
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
