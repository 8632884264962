import ElasticSearch from '@/services/ElasticSearch'
import Cookies from 'js-cookie'
import axios from 'axios'
import store from '../store/index'

const environment = process.env.VUE_APP_ENV
const protocol = process.env.VUE_APP_ELASTIC_BEHAVIOUR_PROTOCOL
const path = process.env.VUE_APP_ELASTIC_BEHAVIOUR_PATH
const port = process.env.VUE_APP_ELASTIC_BEHAVIOUR_PORT
const endpoint = process.env.VUE_APP_ELASTIC_BEHAVIOUR_ENDPOINT

// default prefix
const indexPrefix = process.env.VUE_APP_ELASTIC_INDEX_PREFIX
// behaviour prefix
const behaviourIndexPrefix = process.env.VUE_APP_ELASTIC_BEHAVIOUR_INDEX_PREFIX ?? indexPrefix

const generalInteractionIndex = 'general_interaction'
const resultInteractionIndex = 'result_interaction'
const searchInteractionIndex = 'search_interaction'
const shareInteractionIndex = 'share_interaction'

export default class UserBehaviourService extends ElasticSearch {
  static get protocol () {
    const isDevEnvironment = environment === 'development'
    return isDevEnvironment ? 'http' : protocol
  }

  static get path () {
    return path
  }

  static get port () {
    return port
  }

  static get endpoint () {
    return endpoint || 'api/elastic-behaviour'
  }

  static get environment () {
    const environment = store.getters['environment/environment']
    return {
      name: environment.name,
      slug: environment.slug
    }
  }

  static get user () {
    const user = store.getters['auth/user']
    return user
      ? {
          id: user.username
        }
      : null
  }

  static get searchState () {
    const clientCharacteristics = store.getters['filters/ClientCharacteristic']
    const clientCharacteristicsCount = clientCharacteristics ? clientCharacteristics.length : 0
    const providers = store.getters['filters/Provider']
    const providersCount = providers ? providers.length : 0
    const reaches = store.getters['filters/Reach']
    const reachesCount = reaches ? reaches.length : 0
    const targetGroups = store.getters['filters/Doelgroep']
    const targetGroupsCount = targetGroups ? targetGroups.length : 0
    const tiles = store.getters['filters/Tegel']
    const tilesCount = tiles ? tiles.length : 0
    const townships = store.getters['filters/Townships']
    const townshipsCount = townships ? townships.length : 0
    const owners = store.getters['filters/Owners']
    const ownersCount = owners ? owners.length : 0

    const totalFilterCount = clientCharacteristicsCount + providersCount + reachesCount + targetGroupsCount + tilesCount + townshipsCount + ownersCount

    const searchQuery = store.getters['filters/Zoeken']
    const queryOnlyWhitespace = /^\s*$/.test(searchQuery)

    return {
      query: searchQuery,
      queryEmpty: queryOnlyWhitespace,
      filters: {
        clientCharacteristics: {
          values: clientCharacteristics,
          count: clientCharacteristicsCount
        },
        providers: {
          values: providers,
          count: providersCount
        },
        reaches: {
          values: reaches,
          count: reachesCount
        },
        targetGroups: {
          values: targetGroups,
          count: targetGroupsCount
        },
        tiles: {
          values: tiles,
          count: tilesCount
        },
        townships: {
          values: townships,
          count: townshipsCount
        },
        owners: {
          values: owners,
          count: ownersCount
        }
      },
      filtersCount: totalFilterCount,
      filtersEmpty: totalFilterCount === 0
    }
  }

  static get url () {
    return window.location.origin + window.location.pathname
  }

  static get queryParams () {
    const urlSearchParams = new URLSearchParams(window.location.search)
    return Array.from(urlSearchParams.entries()).map(v => ({ param: v[0], value: v[1] }))
  }

  static getBehaviourIndex (index) {
    return `${behaviourIndexPrefix}-${index}`
  }

  static addGeneralAction (action, actionData = {}) {
    if (!this.elasticPath) {
      return null
    }
    const data = {
      ...this.buildDefaultPayload('previous_general_interaction'),
      action,
      actionData
    }
    return axios.post(`/${this.endpoint}/${this.getBehaviourIndex(generalInteractionIndex)}/_doc/`, data)
  }

  static addResultAction (action, actionData = {}) {
    if (!this.elasticPath) {
      return null
    }
    const data = {
      ...this.buildDefaultPayload('previous_result_interaction'),
      search: this.searchState,
      action,
      actionData
    }
    return axios.post(`/${this.endpoint}/${this.getBehaviourIndex(resultInteractionIndex)}/_doc/`, data)
  }

  static addSearchAction (action, actionData = {}) {
    if (!this.elasticPath) {
      return null
    }
    const data = {
      ...this.buildDefaultPayload('previous_search_interaction'),
      search: this.searchState,
      action,
      actionData
    }
    return axios.post(`/${this.endpoint}/${this.getBehaviourIndex(searchInteractionIndex)}/_doc/`, data)
  }

  static addShareAction (action, value, actionData = {}) {
    if (!this.elasticPath) {
      return null
    }
    const data = {
      ...this.buildDefaultPayload('previous_share_interaction'),
      action,
      value: String(value),
      actionData
    }
    return axios.post(`/${this.endpoint}/${this.getBehaviourIndex(shareInteractionIndex)}/_doc/`, data)
  }

  static buildDefaultPayload (timeStampCookieName = null) {
    const dateCurrentAction = new Date()
    const payload = {
      timestamp: dateCurrentAction.toISOString(),
      user: this.user,
      environment: this.environment,
      location: {
        url: this.url,
        params: this.queryParams
      }
    }

    if (timeStampCookieName !== null) {
      const cookieDate = Cookies.get(timeStampCookieName)
      const datePreviousAction = cookieDate ? new Date(cookieDate) : null
      payload.previous = {
        timestamp: datePreviousAction ? datePreviousAction.toISOString() : null,
        seconds_ago: datePreviousAction ? (datePreviousAction.getTime() - dateCurrentAction.getTime()) / 1000 : null
      }
      Cookies.set(timeStampCookieName, dateCurrentAction)
    }
    return payload
  }
}
