const userPoolId = process.env.VUE_APP_USER_POOL_ID
const userPoolClientId = process.env.VUE_APP_USER_POOL_CLIENT_ID

export default {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: userPoolClientId
  }
}
