export const isExpired = (state) => () => {
  const expirationDate =
    state.expirationDate instanceof Date
      ? state.expirationDate
      : new Date(state.expirationDate)

  return expirationDate < new Date()
}
export const version = state => state.version
export const isBehind = state => () => state.version !== process.env.VUE_APP_VERSION
export const updatedAt = state => state.updatedAt
