import clientCharacteristics from './modules/clientCharacteristics'
import environments from './modules/environments'
// import newsItems from './modules/newsItems'
// import providers from './modules/providers'
// import themes from './modules/themes'
import townships from './modules/townships'
import tiles from './modules/tiles'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    clientCharacteristics,
    environments,
    // newsItems,
    // providers,
    // themes,
    townships,
    tiles
  }
}
