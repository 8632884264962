import TownshipsES from '@/services/TownshipsES'

export const initTownships = ({ commit, getters, dispatch }, force) => {
  if (!getters.initialized || getters.isExpired() || force) {
    return dispatch('fetchTownships')
      .then(() => commit('SET_INITIALIZED', true))
      .then(() => dispatch('refreshExpirationDate'))
  }
}

export const refreshExpirationDate = ({ commit }) => {
  commit('SET_EXPIRATION_DATE')
}

export const fetchTownships = ({ commit }, payload) => {
  commit('SET_LOADING', true)
  return TownshipsES.getTownships()
    .then(response => response.data)
    .then(data => commit('SET_TOWNSHIPS', TownshipsES.mapSource(data)))
    .finally(() => commit('SET_LOADING', false))
}
