import ClientCharacteristicsES from '@/services/ClientCharacteristicsES'

export const initClientCharacteristics = ({ commit, getters, dispatch }, force) => {
  if (!getters.initialized || getters.isExpired() || force) {
    return dispatch('fetchClientCharacteristics')
      .then(() => commit('SET_INITIALIZED', true))
      .then(() => dispatch('refreshExpirationDate'))
  }
}

export const refreshExpirationDate = ({ commit }) => {
  commit('SET_EXPIRATION_DATE')
}

export const fetchClientCharacteristics = ({ commit }) => {
  commit('SET_LOADING', true)
  return ClientCharacteristicsES.getClientCharacteristics()
    .then(response => response.data)
    .then(data => commit('SET_CLIENT_CHARACTERISTICS', ClientCharacteristicsES.mapSource(data)))
    .finally(() => commit('SET_LOADING', false))
}
