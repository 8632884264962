export const SET_ZOEKEN = (state, payload) => { state.Zoeken = payload }
export const SET_TEGEL = (state, payload) => { state.Tegel = payload }
export const SET_THEMA = (state, payload) => { state.Thema = payload }
export const SET_DOELGROEP = (state, payload) => { state.Doelgroep = payload }

export const SET_CLIENT_CHARACTERISTIC = (state, payload) => { state.ClientCharacteristic = payload }
export const SET_PROVIDER = (state, payload) => { state.Provider = payload }
export const SET_REACH = (state, payload) => { state.Reach = payload }
export const SET_AREAS = (state, payload) => { state.Areas = payload }
export const SET_TOWNSHIPS = (state, payload) => { state.Townships = payload }
export const SET_OWNERS = (state, payload) => { state.Owners = payload }
