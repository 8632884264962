import EnvironmentService from '@/services/EnvironmentService'
import EnvironmentsES from '@/services/EnvironmentsES'

export const initEnvironment = ({ commit, getters, dispatch, rootGetters }, force) => {
  if (!getters.initialized || getters.isExpired() || force) {
    if (!EnvironmentService.isPrivateSetup()) {
      commit('SET_INITIALIZED', true)
      return Promise.resolve()
    }

    const environmentSlug = EnvironmentService.getEnvSlug()
    return EnvironmentsES.getEnvironments()
      .then(response => response.data)
      .then(data => {
        return EnvironmentsES.mapSource(data)
      })
      .then(environments => {
        const environment = environments.find(environment => environment.slug === environmentSlug)
        if (!environment) {
          // console.error('No environment found with env from setup');
        }
        // dont save professional data
        delete environment.professionals
        delete environment.news_items
        return dispatch('setEnvironment', environment)
          .then(() => commit('SET_INITIALIZED', true))
          .then(() => dispatch('refreshExpirationDate'))
      })

    // return dispatch('elastic/environments/initEnvironments', force, { root: true }).then(() => {
    //   const environment = rootGetters['elastic/environments/environmentBySlug'](EnvironmentService.getEnvSlug())
    //   if (!environment) {
    //     // console.error('No environment found with env from setup');
    //   }
    //   return dispatch('setEnvironment', environment)
    //     .then(() => commit('SET_INITIALIZED', true))
    //     .then(() => dispatch('refreshExpirationDate'))
    // })
  }
  return Promise.resolve()
}

export const refreshExpirationDate = ({ commit }) => {
  commit('SET_EXPIRATION_DATE')
}

export const setEnvironment = ({ commit }, environment) => {
  commit('SET_ENVIRONMENT', environment)
}
