export const refreshAanbod = ({ commit, getters, dispatch }, force) => {
  if (getters.isExpired() || force) {
    return dispatch('resetAanbod')
  }
}

export const refreshExpirationDate = ({ commit }) => {
  commit('SET_EXPIRATION_DATE')
}

export const resetAanbod = ({ commit, getters, dispatch }) => {
  commit('RESET_AANBOD')
  dispatch('refreshExpirationDate')
}

export const deleteAanbod = ({ commit, getters, dispatch }, payload) => {
  commit('DELETE_AANBOD', payload)
  dispatch('refreshExpirationDate')
}

export const addAanbod = ({ commit, getters, dispatch }, payload) => {
  commit('ADD_AANBOD', payload)
  dispatch('refreshExpirationDate')
}
