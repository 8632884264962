export const Zoeken = state => state.Zoeken
export const Tegel = state => state.Tegel
export const Thema = state => state.Thema
export const Doelgroep = state => state.Doelgroep

export const ClientCharacteristic = state => state.ClientCharacteristic
export const Owners = state => state.Owners
export const Provider = state => state.Provider
export const Reach = state => Array.isArray(state.Reach) && state.Reach.length > 0 ? state.Reach.map((r) => r.value) : []
export const Areas = state => state.Areas
export const Townships = state => state.Townships
