import _ from 'lodash'
import axios from 'axios'

const environment = process.env.VUE_APP_ENV
const protocol = process.env.VUE_APP_ELASTIC_PROTOCOL
const path = process.env.VUE_APP_ELASTIC_PATH
const port = process.env.VUE_APP_ELASTIC_PORT
const endpoint = process.env.VUE_APP_ELASTIC_ENDPOINT
const minimumClusterLength = 1

const indexPrefix = process.env.VUE_APP_ELASTIC_INDEX_PREFIX

export default class ElasticSearch {
  static get protocol () {
    const isDevEnvironment = environment === 'development'
    return isDevEnvironment ? 'http' : protocol
  }

  static get path () {
    return path
  }

  static get port () {
    return port
  }

  static get elasticPath () {
    if (!this.protocol || !this.path || !this.port) {
      return null
    }
    return `${this.protocol}://${this.path}:${this.port}`
  }

  static get endpoint () {
    return endpoint || 'api/elastic-data'
  }

  static get localhost () {
    let path = window.location.toString()
    const hasTrailingSlash = path.endsWith('/')
    if (hasTrailingSlash) {
      path = path.slice(0, -1)
    }
    return path
  }

  static get hostname () {
    if (environment === 'development') {
      return this.localhost.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
    }
    return window.location.hostname
  }

  static get reactiveBase () {
    if (!this.protocol || !this.hostname || !this.endpoint) {
      throw new Error('Incomplete elastic search parameters')
    }
    return `${this.protocol}://${this.hostname}/${this.endpoint}/`
  }

  static get clusters () {
    const clusterList = process.env.VUE_APP_ELASTIC_CLUSTERS
    if (!clusterList || clusterList.length <= minimumClusterLength) {
      return []
    }
    return clusterList.split(',')
  }

  static getEnvironmentIndexForResource (resource) {
    return `${indexPrefix}-${resource}`
  }

  static getClusterIndexPatternForResource (resource) {
    return `${indexPrefix}-*-${resource}`
  }

  static getClusterIndexesForResource (resource) {
    const indexes = []
    const clusterIndexPattern = this.getClusterIndexPatternForResource(resource)
    this.clusters.forEach(cluster => {
      indexes.push(`${cluster}:${clusterIndexPattern}`)
    })
    return indexes
  }

  static getAllIndexesForResource (resource) {
    const indexes = this.getClusterIndexesForResource(resource)
    indexes.push(this.getEnvironmentIndexForResource(resource))
    return indexes
  }

  static buildQuery (query, defaultQuery) {
    return _.mergeWith(defaultQuery, query, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue)
      }
    })
  }

  static mapSource (data) {
    return data.hits.hits.map(hit => hit._source)
  }

  static ensureIndex (resource) {
    return this.indexExists(resource)
      .then((exists) => {
        if (!exists) {
          return this.createIndex(resource)
        }
      })
  }

  static createIndex (resource) {
    const index = this.getEnvironmentIndexForResource(resource)
    return axios.put(`/${this.endpoint}/${index}`)
  }

  static indexExists (resource) {
    const index = this.getEnvironmentIndexForResource(resource)
    return axios.head(`/${this.endpoint}/${index}`, {
      validateStatus: function (status) {
        const succesStatusRange = status >= 200 && status < 300
        return succesStatusRange || status === 404
      }
    })
      .then((response) => {
        return response.status !== 404
      })
  }
}
