export const initialized = state => state.initialized
export const loading = state => state.loading
export const isExpired = (state) => () => {
  const expirationDate =
    state.expirationDate instanceof Date
      ? state.expirationDate
      : new Date(state.expirationDate)

  return expirationDate < new Date()
}
export const tiles = state => state.tiles
export const tileByKey = state => key => state.tiles.find(tile => tile.key === key)
export const tileByName = state => name => state.tiles.find(tile => tile.name === name)
