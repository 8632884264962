export const setZoeken = ({ commit }, payload) => { commit('SET_ZOEKEN', payload) }
export const setTegel = ({ commit }, payload) => { commit('SET_TEGEL', payload) }
export const setThema = ({ commit }, payload) => { commit('SET_THEMA', payload) }
export const setDoelgroep = ({ commit }, payload) => { commit('SET_DOELGROEP', payload) }

export const setClientCharacteristic = ({ commit }, payload) => { commit('SET_CLIENT_CHARACTERISTIC', payload) }
export const setOwners = ({ commit }, payload) => { commit('SET_OWNERS', payload) }
export const setProvider = ({ commit }, payload) => { commit('SET_PROVIDER', payload) }
export const setReach = ({ commit }, payload) => { commit('SET_REACH', payload) }
export const setAreas = ({ commit }, payload) => { commit('SET_AREAS', payload) }
export const setTownships = ({ commit }, payload) => { commit('SET_TOWNSHIPS', payload) }
