
import store from '@/store/index'

const environmentSlug = process.env.VUE_APP_ENVIRONMENT_SLUG

export default class EnvironmentService {
  static isPrivateSetup () {
    return environmentSlug !== null && environmentSlug !== ''
  }

  static getEnvSlug () {
    return environmentSlug
  }

  static getFeaturedAssociation () {
    return store.getters['environment/featuredAssociation']
  }

  static getFeaturedOrganisations () {
    return store.getters['environment/featuredOrganisations']
  }
}
