export const SET_EXPIRATION_DATE = (state) => {
  const date = new Date()
  date.setDate(date.getDate() + 7)
  state.expirationDate = date
}

export const SET_VERSION = (state, payload) => {
  state.version = payload
}

export const SET_UPDATED_AT = (state, payload) => {
  state.updatedAt = payload
}
