export const initialized = state => state.initialized
export const loading = state => state.loading
export const isExpired = (state) => () => {
  const expirationDate =
    state.expirationDate instanceof Date
      ? state.expirationDate
      : new Date(state.expirationDate)

  return expirationDate < new Date()
}
export const environments = state => state.environments
export const environmentBySlug = state => slug => state.environments.find(environment => environment.slug === slug)
