import EnvironmentsES from '@/services/EnvironmentsES'

export const initEnvironments = ({ commit, getters, dispatch }, force) => {
  if (!getters.initialized || getters.isExpired() || force) {
    return dispatch('fetchEnvironments')
      .then(() => commit('SET_INITIALIZED', true))
      .then(() => dispatch('refreshExpirationDate'))
  }
}

export const clearEnvironments = ({ commit }) => {
  commit('SET_ENVIRONMENTS', [])
}

export const refreshExpirationDate = ({ commit }) => {
  commit('SET_EXPIRATION_DATE')
}

export const fetchEnvironments = ({ commit }) => {
  commit('SET_LOADING', true)
  return EnvironmentsES.getEnvironments()
    .then(response => response.data)
    .then(data => {
      data = EnvironmentsES.mapSource(data)
      // remove all data we don't want to store locally
      data = data.map((environment) => {
        delete environment.professionals
        // delete environment.news_items
        return environment
      })
      commit('SET_ENVIRONMENTS', data)
    })
    .finally(() => commit('SET_LOADING', false))
}
